import moment from 'moment';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast } from 'react-toastify';
import appConstants from 'src/core/configs/Constants.config';
// import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
import CryptoJS from 'crypto-js';
import { getLocalDataAsObject } from './CoustomStorage';
const _lodash = require('lodash');

const encryptValue = (text, secret = '123456') => {
  const secretHex = stringToHash(secret);
  const ciphertext = CryptoJS.AES.encrypt(text, secretHex);
  return ciphertext;
};

const decryptValue = (ciphertext, secret = '123456') => {
  const secretHex = stringToHash(secret);
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretHex);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const stringToHash = text => {
  const hmac = CryptoJS.algo.HMAC.create(
    CryptoJS.algo.SHA256,
    'Secret Passphrase',
  ).update(text);
  const hash = hmac.finalize().toString(CryptoJS.enc.Hex);
  return hash;
};

const getUserInfoFromLocal = () => {
  let userInfo = null;
  if (getLocalDataAsObject('user')) {
    userInfo = getLocalDataAsObject('user')[0];
  }
  return userInfo;
};

const toastAlert = (msg, toastOptions = {}) => {
  const options = {
    autoClose: appConstants.toastOptions.autoClose,
    position: appConstants.toastOptions.position,
    type: 'success',
    hideProgressBar: false,
    ...toastOptions,
  };
  return toast(msg, options);
};

const dateFilter = type => {
  let date = new Date();
  let currentYear = date.getFullYear();
  let currentMonth = null;
  let currentDate = '1';
  if (type === 'start_date') {
    currentMonth = date.getMonth();
  } else {
    currentMonth = date.getMonth() + 1;
    currentDate = date.getDate();
  }
  if (currentMonth < 10) {
    currentMonth = `0${currentMonth}`;
  }
  if (currentDate < 10) {
    currentDate = `0${currentDate}`;
  }

  let finalDate = `${currentYear}-${currentMonth}-${currentDate}`;

  return finalDate;
};

const currentMonth = type => {
  let date = new Date();
  let currentYear = date.getFullYear();
  let currentMonth = null;
  let currentDate = '1';
  currentMonth = date.getMonth() + 2;
  if (type === 'end_date') {
    currentDate = date.getDate();
  }
  if (currentMonth < 10) {
    currentMonth = `0${currentMonth}`;
  }
  if (currentDate < 10) {
    currentDate = `0${currentDate}`;
  }

  let finalDate = `${currentYear}-${currentMonth}-${currentDate}`;

  return finalDate;
};

const lastMonth = type => {
  let date = new Date();
  let currentYear = date.getFullYear();
  let currentMonth = date.getMonth();
  let currentDate = '1';
  if (type === 'end_date') {
    currentDate = new Date(currentYear, currentMonth, 0).getDate();
  }
  if (currentMonth < 10) {
    currentMonth = `0${currentMonth}`;
  }
  if (currentDate < 10) {
    currentDate = `0${currentDate}`;
  }

  let finalDate = `${currentYear}-${currentMonth}-${currentDate}`;

  return finalDate;
};

const todayDate = (format = 'YYYY-MM-DD') => {
  const date = moment().format(format);
  return { todayDate: date };
};

const isSameMoment = (date, type = 'day') => {
  return moment(date).isSame(moment(), type);
};
const isBeforeMoment = (date, type = 'day') => {
  return moment(date).isBefore(moment(), type);
};
const convertDate = (date, format = 'YYYY-MM-DD') => {
  return date ? moment(date).format(format) : moment().format(format);
};

const diffDates = (date1, date2) => {
  const diffDays = moment(date1).diff(moment(date2), 'days');
  return { diffDays };
};

const diffBetweenTimes = (sTime, eTime) => {
  // start time and end time
  const startTime = moment(sTime, 'HH:mm:ss a');
  const endTime = moment(eTime, 'HH:mm:ss a');

  // calculate total duration
  const duration = moment.duration(endTime.diff(startTime));

  // duration in hours
  const hours = parseInt(duration.asHours());

  // duration in minutes
  const minutes = parseInt(duration.asMinutes()) % 60;

  // duration in seconds
  const seconds = parseInt(duration.asSeconds()) % 3600;

  return { diffHours: hours, diffMinutes: minutes, diffSeconds: seconds };
};

const replaceContentPlaceHolder = (content, placeholderValue) => {
  return _lodash.template(content)(placeholderValue);
};

const getInputPrefix = val => {
  return val === 'percentage' ? '%' : '₹';
};
const dateValidate = (date, format) => {
  let today = new Date();
  let givenDate = new Date(date);
  if (givenDate > today) {
    return false;
  } else {
    return true;
  }
};

//get permission function (get all permission from session storage)
const getPermissions = params => {
  let permissionArr = [];
  const { permissionType, id } = params;
  const urlPermissions = getLocalDataAsObject('urlData1') || [];
  if (permissionType === 'submenu') {
    permissionArr = urlPermissions.filter(
      item => item.permission_type === permissionType && item.parent === id,
    );
  } else if (permissionType === 'menu') {
    permissionArr = urlPermissions.filter(
      item => item.permission_type === permissionType,
    );
  } else if (permissionType === 'menu_submenu') {
    urlPermissions.forEach(value => {
      if (value.parent === id) {
        permissionArr.push({
          _tag: 'CSidebarNavItem',
          name: value?.title || '',
          to: value?.routes || '',
        });
      }
    });
  } else {
    permissionArr = urlPermissions;
  }
  return permissionArr;
};

const isPermission = action => {
  let permission = getPermissions({ permissionType: 'all' });
  let result = permission.filter(item => item.route_key === action);
  if (result && result.length) {
    return true;
  } else {
    return false;
  }
};

const getOutputPrefix = (name, val) => {
  return name === 'basic' && val === 'percentage'
    ? 'of CTC'
    : val === 'percentage'
    ? 'of BASIC'
    : '';
};

const isTypeGroup = type => {

  return appConstants.groupTypes.indexOf(type) !== -1;
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getFileExtension = fileName => {
  if (fileName?.length) {
    const value = fileName.split('.');
    return value[value.length - 1];
  }
  return '';
};

const getFileSize = payload => {
  const fileSize = payload?.size || 0;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (fileSize === 0) {
    return;
  }

  const size = parseInt(Math.floor(Math.log(fileSize) / Math.log(1024)));

  return fileSize
    ? `${Math.round(fileSize / Math.pow(1024, size), 2)} ${sizes[size]}`
    : null;
};

const getMessageType = (extension = '') => {
  const ex = extension.toLowerCase();
  const {
    textTypes,
    imageTypes,
    audioTypes,
    videoTypes,
    pageLayoutTypes,
    spreadSheetTypes,
    compresedFileTypes,
    dataFileTypes,
    otherFilesTypes,
  } = appConstants.fileTypes;
  //add new extentions
  let textTypesArr = textTypes;
  textTypesArr.push('yml', 'log');

  let audioTypesArr = audioTypes;
  //audioTypesArr.push('mpeg');

  let videoTypesArr = videoTypes;
  videoTypesArr.push('3gp');

  if (textTypesArr.includes(ex)) {
    return 'TEXT';
  } else if (audioTypesArr.includes(ex)) {
    return 'AUDIO';
  } else if (videoTypes.includes(ex)) {
    return 'VIDEO';
  } else if (imageTypes.includes(ex)) {
    return 'IMAGE';
  } else if (pageLayoutTypes.includes(ex)) {
    return 'PAGE_LAYOUT';
  } else if (spreadSheetTypes.includes(ex)) {
    return 'SPREADSHEET';
  } else if (compresedFileTypes.includes(ex)) {
    return 'COMPRESSED_FILES';
  } else if (dataFileTypes.includes(ex)) {
    return 'DATA_FILES';
  } else if (otherFilesTypes.includes(ex)) {
    return 'OTHER';
  }
  return 'UNKNOWN_MEDIA_TYPE';
};

const getPreviewSupportStatus = (extension = '', isForThumb = false) => {
  const ex = extension.toLowerCase();
  const {
    textTypes,
    imageTypes,
    audioTypes,
    videoTypes,
    pageLayoutTypes,
    spreadSheetTypes,
    compresedFileTypes,
    dataFileTypes,
    otherFilesTypes,
    textPreviewTypes,
    officePreviewTypes,
    googlePreviewTypes,
    thumbTypes,
  } = appConstants.fileTypes;

  if (imageTypes.includes(ex)) {
    return {
      supported: true,
      mime: 'image',
    };
  } else if (videoTypes.includes(ex) && !isForThumb) {
    return {
      supported: true,
      mime: 'video',
    };
  } else if (thumbTypes.includes(ex) && isForThumb) {
    return {
      supported: true,
      mime: 'video',
    };
  } else if (textPreviewTypes.includes(ex)) {
    return {
      supported: true,
      mime: 'textPreview',
    };
  } else if (pageLayoutTypes.includes(ex)) {
    return {
      supported: true,
      mime: 'pdf',
    };
  } else if (officePreviewTypes.includes(ex)) {
    return {
      supported: true,
      mime: 'office',
    };
    // } else if (audioTypes.includes(ex)) {
    //   return {
    //     supported: true,
    //     mime: 'audio',
    //   };
  } else if (googlePreviewTypes.includes(ex)) {
    return {
      supported: true,
      mime: 'google',
    };
  }

  return {
    supported: false,
    mime: '',
  };
};

const validateTimeFormat = input => {
  const res = {
    isValid: false,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
  };
  const pattern = /^(?=.*\d+w)(?=.*\d+d)(?=.*\d+h)(?=.*\d+m)(\s*\d+[wdhm]\s*)+$/;
  if (!pattern.test(input)) {
    res.isValid = false;
    return res;
  }
  const regex = /(\d+[wdhm])/g;
  const matches = input.match(regex);

  // Extract values from matched units and update the corresponding unit
  for (const match of matches) {
    const value = parseInt(match);
    if (match.includes('w')) {
      res.weeks += value;
    } else if (match.includes('d')) {
      res.days += value;
    } else if (match.includes('h')) {
      res.hours += value;
    } else if (match.includes('m')) {
      res.minutes += value;
    }
  }
  res.isValid = true;
  // You can now use the extracted values as needed
  // console.log({res});

  return res;
};

const timeRemainingData = (val1, val2) => {
  const series = [40, 8, 1, 0.6];
  let response = '';
  let pr = '';
  const res1 = validateTimeFormat(val1);
  const res2 = validateTimeFormat(val2);
  if (res1.isValid && res2.isValid) {
    const resu1 =
      res1['weeks'] * 5 * 8 +
      res1['days'] * 8 +
      res1['hours'] +
      res1['minutes'] / 60;
    const resu2 =
      res2['weeks'] * 5 * 8 +
      res2['days'] * 8 +
      res2['hours'] +
      res2['minutes'] / 60;
    let realValue;
    if (resu2 > resu1) {
      //+
      realValue = resu2 - resu1;
    } else {
      //-
      pr = '-';
      realValue = resu1 - resu2;
    }
    // console.log(resu1, resu2, realValue.toFixed(2));
    series.forEach(elem => {
      let temp = 0;
      // if(realValue>0){
      //   if(elem===40){
      //     response = response + ((realValue%40)/10) + 'w ';
      //     realValue = realValue-(((realValue%40)/10)*40);
      //   }else if(elem===8){
      //     response = response + realValue%8 + 'd ';
      //     realValue = realValue-(((realValue%8)/10)*8);
      //   }else if(elem===1){
      //     let temp = realValue%1;
      //     realValue = realValue-(((realValue%1)/1)*1);
      //     if(realValue>60){
      //       temp = temp  + 1;
      //       realValue = realValue - 1;
      //     }
      //     response = response + temp + 'h ';
      //   }else{
      //     response = response + realValue + 'm ';
      //   }
      // }else{
      //   response = response + ' 0' + elem;
      // }
      while (realValue >= elem) {
        temp = temp + 1;
        if (elem === 0.6) {
          realValue = realValue - 1;
        } else {
          realValue = realValue - elem;
        }
      }
      if (elem === 40) {
        response = response + temp + 'w ';
      } else if (elem === 8) {
        response = response + temp + 'd ';
      } else if (elem === 1) {
        response = response + temp + 'h ';
      } else if (elem === 0.6) {
        response = response + temp + 'm ';
      }
    });
    return pr + response;
  } else {
    return 0;
  }
};

const getProjectKey = value => {
  let matches = value.match(/\b(\w)/g);
  let acronym = matches.join('');
  return acronym;
};

const getLetersAndNumbersOnly = value => {
  const matches = /^[A-Za-z0-9]*$/;
  return matches.test(value);
};
function getTimeInSeconds(str) {
  let curr_time = [];

  curr_time = str.split(':');
  curr_time =
    curr_time.length === 1
      ? [...curr_time, '00', '00']
      : curr_time.length === 2
      ? [...curr_time, '00']
      : curr_time;
  for (let i = 0; i < curr_time.length; i++) {
    // console.log('time split:', curr_time[i]);
    curr_time[i] ? (curr_time[i] = parseInt(curr_time[i])) : parseInt('00');
  }

  let t = curr_time[0] * 60 * 60 + curr_time[1] * 60 + curr_time[2];

  return t;
}
function convertSecToTime(t) {
  let hours = Math.floor(t / 3600);
  let hh = hours < 10 ? '0' + hours.toString() : hours.toString();
  let min = Math.floor((t % 3600) / 60);
  let mm = min < 10 ? '0' + min.toString() : min.toString();
  let sec = (t % 3600) % 60;
  let ss = sec < 10 ? '0' + sec.toString() : sec.toString();
  let ans = hh + ':' + mm + ':' + ss;
  return ans;
}
function timeGap(st, et) {
  let t1 = getTimeInSeconds(st);
  let t2 = getTimeInSeconds(et);

  let time_diff = t1 - t2 < 0 ? t2 - t1 : t1 - t2;

  return convertSecToTime(time_diff);
}
function timeAdd(st, et) {
  let t1 = getTimeInSeconds(st);
  let t2 = getTimeInSeconds(et);

  let time_add = t1 + t2;

  return convertSecToTime(time_add);
}
const comapareTime = (str1, str2) => {
  if (!str1 || !str2) {
    return 'none';
  }
  str1 = str1.split(':');
  str2 = str2.split(':');
  let totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[2]);
  let totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[2]);
  if (totalSeconds1 > totalSeconds2) {
    return 'isAfter';
  }
  if (totalSeconds1 < totalSeconds2) {
    return 'isBefore';
  } else {
    return 'same';
  }
};

// to get month data between two date
const enumerateMonthBetweenDates  = (startDate, endDate)=>{
  let date = []
  while(moment(startDate) <= moment(endDate)){
    date.push({date:startDate, day: moment(startDate).format("dddd"), month: moment(startDate).format("MM"), year: moment(startDate).format("YYYY")});
    startDate = moment(startDate).add(1, 'month').format("YYYY-MM-DD");
  }
  return date;
}

function getFinancialYearMonths(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const months = [];

  while (start.isSameOrBefore(end, 'month')) {
    months.push({
      value: start.format('YYYY-MM'), 
      label: start.format('MMMM YYYY'),
      months: [start.format("MM")],
    });
    start.add(1, 'month');
  }

  return months;
}

const isAuthenticated = () => {
  const otpDate = sessionStorage?.otpExpireTime;
  if(!otpDate){
    return false;
  }else{
    if(moment(otpDate).format('YYYY-MM-DD HH:mm:ss') > moment().format('YYYY-MM-DD HH:mm:ss')){
      return true;
    }else{
      return false;
    }
  }
}
const toTitleCase = (data)=>{
  return data
  ?.split('_')
  ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  ?.join(' ');
}
const checkMaxWordLimit = (text,limit) => {
  const words = text.trim().split(/\s+/).filter(word => word?.length > 0);
  return words?.length <= limit 
}
const wordCounter=(words)=>{
  const wordCount = words && words?.split(/\s+/)?.filter(Boolean).length;
  return wordCount ||0
}

const createShortName = (value) => {
  const short_name = value?.split(" ")?.map(word => word.charAt(0).toUpperCase()).join('')
  return short_name
}

export {
  getUserInfoFromLocal,
  dateFilter,
  currentMonth,
  lastMonth,
  todayDate,
  isSameMoment,
  isBeforeMoment,
  convertDate,
  dateValidate,
  toastAlert,
  diffDates,
  diffBetweenTimes,
  replaceContentPlaceHolder,
  getInputPrefix,
  encryptValue,
  decryptValue,
  getOutputPrefix,
  getPermissions,
  isPermission,
  isTypeGroup,
  capitalizeFirstLetter,
  getFileExtension,
  getFileSize,
  getMessageType,
  getPreviewSupportStatus,
  validateTimeFormat,
  timeRemainingData,
  getProjectKey,
  getLetersAndNumbersOnly,
  timeGap,
  comapareTime,
  timeAdd,
  enumerateMonthBetweenDates,
  isAuthenticated,
  toTitleCase,
  checkMaxWordLimit,
  wordCounter,
  getFinancialYearMonths,
  createShortName,

};
