/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useRef } from 'react';
import swal from 'sweetalert';
import {
  CRow,
  CButton,
  CCardFooter,
  CCol,
  CFormGroup,
  CLabel,
} from '@coreui/react';
import { toast, ToastContainer } from 'react-toastify';
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import moment from 'moment';
import { checkForScriptTags } from 'src/utils/ValidationHelper';
import Select from 'react-select';

const CreateNewCandidateWithoutMRF = props => {
  const { createNewCandidateWithoutMRF, isCreateCandidateWithoutMRFModalOpen, setIisCreateCandidateWithoutMRFModalOpen } = props;
  let api = new API();
  const emailRegix = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const [fileUpload, setFileUpload] = useState();
  const [dynamicMrfDetails, setDynamicMrfDetails] = useState();
  const [allSources, setAllSources] = useState();
  const [allDesignation, setAllDesignation] = useState('')
  const [selectedDesignation, setSelectedDesignation] = useState('')
  const [agreementLetter, setAgreementLetter] = useState('')
  const [candidateDetails, setCandidateDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    dob: '',
    designation: '',
    mobile: '',
    experience: '',
    work_location: '',
    current_location: '',
    source: '',
    remarks: '',
    agreement_start_date: '',
    agreement_end_date: ''
  });
  const resumeFileRef = useRef(null);
  const agreementFileRef = useRef(null);


  const createNewCondidate = async () => {
    if (!(/^[a-zA-Z]+$/.test(candidateDetails.first_name)) || !(/^[a-zA-Z ]*$/.test(candidateDetails.last_name))) {
      toast.error("first and last name field should be allowed alphabetic characters.")
      return
    }
    let checkScriptvalid = ["email", "designation", "work_location", "current_location", "remarks"]
    const checkScriptvalidation = checkScriptvalid.filter(field => checkForScriptTags(candidateDetails[field]));

    if (checkScriptvalidation.length != checkScriptvalid.length) {
      toast.error("Invalid input detected")
      return

    }
    const formData = new FormData();
    formData.append('candidate_resume', fileUpload);
    formData.append('first_name', candidateDetails.first_name);
    formData.append('last_name', candidateDetails.last_name);
    formData.append('email', candidateDetails.email.trim());
    formData.append('dob', candidateDetails.dob);
    formData.append('designation', selectedDesignation?.role_name);
    formData.append('designation_id', selectedDesignation?.id);
    formData.append('mobile', candidateDetails.mobile);
    formData.append('experience', candidateDetails.experience);
    formData.append('source', candidateDetails.source);
    formData.append('work_location', candidateDetails.work_location);
    formData.append('current_location', candidateDetails.current_location);
    formData.append('remarks', candidateDetails.remarks);

    if ((selectedDesignation && selectedDesignation?.role_name?.includes('Consultant'))) {
      formData.append('agreement_start_date', candidateDetails.agreement_start_date);
      formData.append('agreement_end_date', candidateDetails.agreement_end_date);
      formData.append('agreement_letter', agreementLetter);
    }

    if ((!selectedDesignation?.role_name?.includes('Consultant')) && !fileUpload) {
      toast.error("Candidate Resume is required");
      return
    }
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to create candidate ${candidateDetails.email}`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Create'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.putPostFileFun(
          config.createCandidateWithoutMrf,
          formData,
        );
        if (result && result.code === 200) {
          toast.success(result?.message);
          setIisCreateCandidateWithoutMRFModalOpen(!isCreateCandidateWithoutMRFModalOpen);
        } else {
          toast.error(result?.error?.response?.data?.message);
        }
      }
    });
  };
  const onChangeFunction = e => {
    setCandidateDetails({
      ...candidateDetails,
      [e.target.name]: e.target.value,
    });
    // console.log("_________________________", condidateDetails)
  };
  const onChangeFunctionUploadResume = async (e) => {
    let files = e.target.files;
    setFileUpload(files[0]);
    const formData = new FormData();
    formData.append('file', files[0]);
    let result = await api.putPostFileFun(
      config.resumeParser,
      formData,
    );
    if (result && result?.code === 200) {
      console.log("rs parser>>>>>>>>>", result)
      let name = result?.data?.name?.split(" ");
      setCandidateDetails({
        ...candidateDetails,
        'email': result?.data?.email || '',
        'mobile': result?.data?.mobile || '',
        'work_location': result?.data?.city || '',
        'current_location': result?.data?.city || '',
        'first_name': name ? name[0] : '',
        'last_name': (name && name?.length === 2) ? name[1] : (name && name?.length === 3) ? name[1] + name[2] : ''
      },
      )
    }
  };

  const getDynamicDetails = async () => {
    let result = await api.get(config.mrfdynamicdetailsform);
    if (result && result.code === 200) {
      setDynamicMrfDetails(result.data[0]);
    }
  };

  const getAllSources = async () => {
    let result = await api.get(config.sources);
    if (result && result.code === 200) {
      setAllSources(result.data);
    }
  };

  useEffect(() => {
    getDynamicDetails();
    getAllSources();
    getAllDesignationList()
  }, []);

  const getAllDesignationList = async () => {
    let result = await api.get(config.getAllDesignationList);
    if (result && result.code === 200) {
      setAllDesignation(result.data);
    }
  };

  const handleSelectData = (e) => {

    setCandidateDetails((pre) => ({
      ...pre,
      agreement_start_date: '',
      agreement_end_date: ''
    }))
    if (agreementFileRef.current) {
      agreementFileRef.current.value = '';
    }

    setAgreementLetter('')
    setSelectedDesignation(e)
  }

  useEffect(()=>{
    return (()=>{
      if(!isCreateCandidateWithoutMRFModalOpen){
        
        resetForm()
      }
    })
  },[isCreateCandidateWithoutMRFModalOpen])

  const resetForm =()=>{
    setCandidateDetails({
      first_name: '',
      last_name: '',
      email: '',
      dob: '',
      designation: '',
      mobile: '',
      experience: '',
      work_location: '',
      current_location: '',
      source: '',
      remarks: '',
      agreement_start_date: '',
      agreement_end_date: ''
    })
    setAgreementLetter('')
    setFileUpload('')
    setSelectedDesignation('')
  }

  return (
    <div className="">
      <AvForm
        onValidSubmit={createNewCondidate}
        action=""
        encType="multipart/form-data"
        className="form-horizontal"
      >
        <CRow>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>
                  First Name <b style={{ color: 'red' }}>*</b>
                </CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvInput
                  required={true}
                  value={candidateDetails.first_name}
                  onChange={onChangeFunction}
                  name="first_name"
                  placeholder="Enter Candidate First Name"
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Last Name</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvInput
                  value={candidateDetails.last_name}
                  onChange={onChangeFunction}
                  name="last_name"
                  placeholder="Enter Candidate Last Name"
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>
                  Email <b style={{ color: 'red' }}>*</b>
                </CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvInput
                  required
                  value={candidateDetails.email}
                  onChange={onChangeFunction}
                  name="email"
                  placeholder="Enter Email"
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>
                  Mobile No. <b style={{ color: 'red' }}>*</b>
                </CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvInput
                  required
                  value={candidateDetails.mobile}
                  onChange={onChangeFunction}
                  name="mobile"
                  placeholder="Enter Mobile No."
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>
                  Designation <b style={{ color: 'red' }}>*</b>
                </CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <Select
                  value={selectedDesignation}
                  onChange={(e) => handleSelectData(e)}
                  options={allDesignation && allDesignation.length > 0 && allDesignation.map(val => {
                    val.label = val.role_name
                    val.value = val.id
                    return val
                  })}
                />
              </CCol>
            </CFormGroup>
          </CCol>

          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Current Location</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <AvInput
                  value={candidateDetails.current_location}
                  onChange={onChangeFunction}
                  name="current_location"
                  placeholder="Enter Current Location"
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Work Location</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvInput
                  value={candidateDetails.work_location}
                  onChange={onChangeFunction}
                  name="work_location"
                  placeholder="Enter Preferred Work Location"
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>DOB</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvInput
                  type="date"
                  value={candidateDetails.dob}
                  onChange={onChangeFunction}
                  name="dob"
                  placeholder="Enter DOB"
                  max={moment()
                    .subtract(18, 'years')
                    .format('YYYY-MM-DD')}
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Work Experience</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvInput
                  className="letter"
                  type="select"
                  value={candidateDetails.experience}
                  name="experience"
                  onChange={onChangeFunction}
                  id="experience"
                >
                  <option hidden>Select experience . . .</option>
                  {dynamicMrfDetails &&
                    dynamicMrfDetails.Job_Experiences.map(data => (
                      <option value={data.key}>{data.experience}</option>
                    ))}
                </AvInput>
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>
                  Source <b style={{ color: 'red' }}>*</b>
                </CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <AvInput
                  required
                  className="letter"
                  type="select"
                  value={candidateDetails.source}
                  name="source"
                  onChange={onChangeFunction}
                  id="source"
                >
                  <option hidden>Select source of hiring . . .</option>
                  {allSources &&
                    allSources.map(data => (
                      <option value={data.id}>{data.category_key}</option>
                    ))}
                </AvInput>
              </CCol>
            </CFormGroup>
          </CCol>
          {selectedDesignation && selectedDesignation?.role_name?.includes('Consultant') && <>
            <CCol md="6">
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Agreement Start Date <b style={{ color: 'red' }}>*</b></CLabel>
                </CCol>

                <CCol xs="12" md="9">
                  <AvInput
                    type="date"
                    required
                    value={candidateDetails.agreement_start_date}
                    onChange={onChangeFunction}
                    name="agreement_start_date"

                  />
                </CCol>
              </CFormGroup>
            </CCol>
            <CCol md="6">
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Agreement End Date <b style={{ color: 'red' }}>*</b></CLabel>
                </CCol>

                <CCol xs="12" md="9">
                  <AvInput
                    type="date"
                    required
                    value={candidateDetails.agreement_end_date}
                    onChange={onChangeFunction}
                    name="agreement_end_date"
                    min={candidateDetails.agreement_start_date}
                  />
                </CCol>
              </CFormGroup>
            </CCol>
            <CCol md="6">
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>
                    Agreement Letter <b style={{ color: 'red' }}>*</b>
                  </CLabel>
                </CCol>
                <CCol xs="12" md="9">
                  <input
                    required
                    className="form-control"
                    onChange={(e) => setAgreementLetter(e.target?.files[0])}
                    type="file"
                    ref={agreementFileRef}
                  ></input>
                </CCol>
              </CFormGroup>
            </CCol>
          </>}
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>
                  Select Resume {(!selectedDesignation?.role_name?.includes('Consultant')) && <b style={{ color: 'red' }}>*</b>}
                </CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <input
                  required
                  className="form-control"
                  onChange={onChangeFunctionUploadResume}
                  type="file"
                  ref={resumeFileRef}
                ></input>
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Remarks</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <AvInput
                  type="text"
                  value={candidateDetails.remarks}
                  onChange={onChangeFunction}
                  name="remarks"
                  placeholder="Enter Remarks if any...."
                />
              </CCol>
            </CFormGroup>
          </CCol>
        </CRow>
        <CCol md="3">
          <CLabel style={{ fontSize: '12px', color: 'red' }}>
            * Indicates Mandatory Fields
          </CLabel>
        </CCol>

        <CCardFooter>
          <CButton type="submit" className="btn btn-sm btn-primary mr-3 mt-2">
            Create Candidate
          </CButton>
        </CCardFooter>
      </AvForm>
      <ToastContainer />
    </div>
  );
};
export default CreateNewCandidateWithoutMRF;
