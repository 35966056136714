import React, { useEffect, useState } from 'react';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { CSVLink, CSVDownload } from 'react-csv';
import ReactExport from 'react-data-export';
import { isPermission } from 'src/utils/common';
import { DatasetController } from 'chart.js';
import moment from 'moment';
const HrDashboardModal = props => {
  const [showViewDetails, setShowViewDetails] = useState(false);
  const [showMrf, setShowMrf] = useState(true);
  const [index, setIndex] = useState(0);
  const { isOpen, isViewModalOpen, modalHeading, onclickMrfData } = props;
  const [getAllCandidate, setGetAllCandidate] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const api = new API();
  const DataSet = [
    {
      columns: [
        { title: 'MRF ID', width: { wpx: 120 } },
        { title: 'Applying For', width: {wpx: 120 } },
        { title: 'Department', width: { wpx: 130 } },
        { title: 'Designation', width: { wpx: 130 } },
        { title: 'Created By', width: { wpx: 130 } },
        { title: 'Recruiter', width: { wpx: 130 } },
        { title: 'Status', width: { wpx: 110 } },
      ],
      data: (onclickMrfData || []).map((data) => [
        { value: data.id },
        { value: data.applying_for },
        { value: data.requisition_for_department },
        { value: data.designation },
        { value: data.created_by_name },
        { value: data.assigned_to || "Not Assigned" },
        { value: data.mrf_active_status },
      ]),
    },
  ];

  useEffect(() => {
    setShowViewDetails(false);
  }, [onclickMrfData]);

  const getCandidateByMrf = async item => {
    let data = {
      mrf_id: item.id,
      mrf_applying_for: item.applying_for,
      type: 'none',
      // type : type
    };
    let result = await api.post(config.view_candidate_on_mrf, data);
    if (result && result.code === 200) {
      // console.log("get candidate by mrf id", result)
      setGetAllCandidate(result && result.data);
    } else {
      // console.log(result && result.message)
    }
    setShowViewDetails(true);
    setShowMrf(false);
  };
  // const downloadData=()=>{

  // let newData=  onclickMrfData&&onclickMrfData.length>0&&onclickMrfData.map((item)=>(
  //     Object.values(item)

  //   ))

  //   const ws = XLSX.utils.aoa_to_sheet(newData);

  //   const wb = XLSX.utils.book_new();
  //  let dd= XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  //   console.log("hola 123",dd)

  //   /* generate XLSX file and send to client */
  //   XLSX.writeFile(wb, `${modalHeading}.xlxs`);
  // }

  return (
    <div className="">
      <CModal
        show={isOpen}
        onClose={() => isViewModalOpen(!isOpen)}
        size="lg"
        style={{ marginTop: '100px' }}
      >
        <CModalHeader>
          <CModalTitle style={{ color: 'white' }}>{modalHeading}</CModalTitle>
          {/* <CSVLink filename={`${modalHeading}.xlxs`} data={onclickMrfData || [{}]}> */}
          {/* <button className='btn btn-secondary m-1 float-right' onClick={downloadData}>
                      <i className="fa fa-download" aria-hidden="true">
                      </i> download
                    </button> */}
          {isPermission('download_mrf_tab_report') && onclickMrfData?.length> 0 && (
            <ExcelFile
              filename={modalHeading}
              element={
                <button
                  type="button"
                  className="btn btn-primary float-right m-3"
                >
                  <i className="fa fa-download" aria-hidden="true"></i> Download
                  Data
                </button>
              }
            >
              <ExcelSheet dataSet={DataSet} name={modalHeading} />
            </ExcelFile>
          )}
          {/* </CSVLink> */}
        </CModalHeader>
        <CModalBody style={{ height: '500px', overflow: 'auto' }}>
          {showViewDetails === false && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">MRF ID</th>
                  <th scope="col">Applying For</th>
                  <th scope="col">Department</th>
                  <th>Designation</th>
                  {isPermission('view_candidate_mrf_data') && (
                    <th>View Candidate</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {onclickMrfData &&
                  onclickMrfData.length > 0 &&
                  onclickMrfData.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.id}</td>
                      <td>{item.applying_for}</td>
                      <td>{item.requisition_for_department}</td>
                      <td>{item.designation}</td>
                      {isPermission('view_candidate_mrf_data') && (
                        <td>
                          <CButton
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Candidate"
                            className="btn btn-primary"
                            onClick={() => getCandidateByMrf(item)}
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </CButton>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          {showViewDetails === true &&
            getAllCandidate &&
            getAllCandidate.length > 0 && (
              <>
                <table className="table table-lg table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Recruiter</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Experience </th>
                      <th scope="col">DOB</th>
                      <th scope="col">Created At </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAllCandidate &&
                      getAllCandidate.length > 0 &&
                      getAllCandidate.map((data, index) => (
                        <tr>
                          <td scope="row">{index+1}</td>
                          <td scope="col">{data.candidate_created_by}</td>
                          <td scope="col">{data.name}</td>
                          <td scope="col">{data.email}</td>
                          <td scope="col">{data.mobile}</td>
                          <td scope="col">{data.experience}</td>
                          <td scope="col">{data.dob}</td>
                          <td scope="col">{moment(data.created_at).format("HH:mm:ss DD-MM-YYYY")}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          {showViewDetails === true &&
            getAllCandidate &&
            getAllCandidate.length == 0 && (
              <div>
                <h4 align="center" style={{ color: 'red' }}>
                  No candidate created for this MRF
                </h4>
              </div>
            )}
        </CModalBody>
        <CModalFooter>
          {showViewDetails === true && (
            <CButton
              color="success"
              onClick={() => {
                setShowMrf(true);
                setShowViewDetails(false);
              }}
            >
              <i className="fa fa-backward" aria-hidden="true"></i>
            </CButton>
          )}
          <CButton color="success" onClick={() => isViewModalOpen(!isOpen)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton>{' '}
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default HrDashboardModal;
