// import { exact } from 'prop-types';
import React from 'react';
import { withCookies } from 'react-cookie';
import { useCookies } from 'react-cookie';
import AttendenceInOut from './admin-dashboard/payroll-admin/attendanceInOut';
import { getLocalDataAsObject } from './utils/CoustomStorage';
import mysoftwareassets from './views/ASSETS MANAGEMENT/EMP_Asset_Management/asset_profile_emp/mysoftwareassets';

const Status = React.lazy(() => import('./views/recruitment/Status'));
const JD_Template = React.lazy(() => import('./views/recruitment/JD_Template'));
const Detail = React.lazy(() => import('./views/detail/Detail'));
const Policies = React.lazy(() => import('./views/policies/Policies'));
const Offer_Letter = React.lazy(() =>
  import('./views/offer_letter/Offer_Letter'),
);
const Documents = React.lazy(() => import('./views/documents/Documents'));
const ChangePassword = React.lazy(() =>
  import('./views/changepassword/ChangePassword'),
);
const Training1 = React.lazy(() => import('./views/training/Training1'));
const Training2 = React.lazy(() => import('./views/training/Training2'));
const MRF_Manager = React.lazy(() => import('./views/mrf_manager/MRF_Manager'));
const Create_MRF = React.lazy(() => import('./views/create_mrf'));
const Interview = React.lazy(() => import('./views/interview/Interview'));
const Referral = React.lazy(() => import('./views/referral/Referral'));
const ExitInterview = React.lazy(() =>
  import('./views/seperation/exit_interview/exit_interview'),
);
const JobDiscription = React.lazy(() =>
  import('./views/referral/JobDiscription'),
);
const AdminDashboardAppraisals = React.lazy(() =>
  import('../src/admin-dashboard/appraisals/index'),
);
const Seperation = React.lazy(() => import('./views/seperation/Seperation'));
const MRF_Finance = React.lazy(() => import('./views/mrf_finance/MRF_Finance'));
const Admin_Department = React.lazy(() =>
  import('./views/admin/Admin_Department'),
);
const create_kudos = React.lazy(() =>import('./Community/kudos/create_kudos'));
const feeds = React.lazy(() =>import('./Community/kudos/feeds'));
const KudosApprovalManagement = React.lazy(() =>import('./Community/kudos/kudosApproval'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const SeperationReceived = React.lazy(() =>
  import('./views/seperation/received_sepration/SeperationReceived'),
);

const My_info = React.lazy(() => import('./views/my_info/My_info'));
//V ROUTE
const Hr_Dashboard = React.lazy(() => import('./HR-PANEL/hr-dashboard'));
const Hr_Manager_Dashboard = React.lazy(() =>
  import('./HR-MANAGER-PANEL/hr-manager-dashboard'),
);
const Hr_1_Dashboard = React.lazy(() =>
  import('./HR-MANAGER-PANEL/hr-dashboard'),
);
const LandingDashboard = React.lazy(() =>
  import('./HR-MANAGER-PANEL/landing_dashboard/LandingDashboard'),
);
const FullNFinal = React.lazy(() => import('./views/seperation/full_n_final'));
const Requestemployeeseparation = React.lazy(() =>
  import('./views/seperation/Requestemployeeseparation'),
);
const EmployeeExitInterview = React.lazy(() =>
  import('./views/seperation/exit_interview/exit_interview'),
);

const AllSeparationHR = React.lazy(() =>
  import('./views/seperation/all_separation_hr/all_separation'),
);
const AssetManagement = React.lazy(() => import('./views/ASSETS MANAGEMENT'));
const SoftwareAssetManagement = React.lazy(() => import('./views/ASSETS MANAGEMENT/IT_Software_Management/softwareassetmanagement'));
const FmsManagement = React.lazy(() => import('./views/FMS'));
const EmployeeAssetsManagement = React.lazy(() =>
  import('./views/ASSETS MANAGEMENT/EMP_Asset_Management'),
);
const ItSupportAssetsManagement = React.lazy(() =>
  import('./views/ASSETS MANAGEMENT/IT_Support_Asset_Management'),
);
const AdminDashboard = React.lazy(() => import('./admin-dashboard'));
const AdminDashboardAccounts = React.lazy(() =>
  import('./admin-dashboard/accounts'),
);
const PmsManagement = React.lazy(() => import('./views/PMS'));
const PmsEmployee = React.lazy(() => import('./views/PMS/pms_employee'));
const PmsAdmin = React.lazy(() => import('./views/PMS/pms_admin'));
const PmsKpiGuidelines = React.lazy(() =>
  import('./views/PMS/pms_employee/pms_kpi_guidelines'),
);
const PmsManageKra = React.lazy(() =>
  import('./views/PMS/pms_employee/pms_manage_kra'),
);
const PmsMyKpi = React.lazy(() =>
  import('./views/PMS/pms_employee/pms_my_kpi'),
);
const StartRatingfeedback = React.lazy(() =>
  import('./views/PMS/pms_employee/pms_my_team/RatingPKI'),
);
const PmsMyTeam = React.lazy(() =>
  import('./views/PMS/pms_employee/pms_my_team'),
);
const PmsTeamRanking = React.lazy(() =>
  import('./views/PMS/pms_employee/pms_team_ranking'),
);
const OnboardingEmployeeDashboard = React.lazy(() =>
  import('./views/ONBOARDING_EMPLOYEE'),
);
const PmsTeamHierarchy = React.lazy(() =>
  import('./views/PMS/pms_employee/team-hierarchy'),
);
const ProbationReviews = React.lazy(() =>
  import('./views/PMS/ProbationReview'),
);
const AdminProbationReviews = React.lazy(() =>
  import('./views/PMS/AdminProbationReview'),
);
const EmployeeProbation = React.lazy(() =>
  import('./views/PMS/pms_employee/all_employee_rating/employeeProbation'),
);
const FeedbackDetails = React.lazy(() =>
  import('./admin-dashboard/accounts/employeeFeedback'),
);
const AttendanceManagement = React.lazy(() => import('./views/ATTENDANCE'));
const LeaveManagement = React.lazy(() => import('./views/ATTENDANCE/LEAVE'));
const HolidayList = React.lazy(() => import('./views/ATTENDANCE/HOLIDAY'));
const attritionDashboard = React.lazy(()=> import('./views/ATTENDANCE/attrition_dashboard'))
const shrinkageDashboard = React.lazy(()=>import('./views/ATTENDANCE/shrinkage_dashboard'))
const AddHoliday = React.lazy(() =>
  import('./views/ATTENDANCE/HOLIDAY/AddHoliday'),
);
const ITDeclaration = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/AddInvestment'),
);
const ViewOtherAttendance = React.lazy(() =>
  import('./views/ATTENDANCE/View_other_attendance'),
);
const TeamAttendance = React.lazy(() =>
  import('./views/ATTENDANCE/team_attendance'),
);
const AttendanceSummary = React.lazy(() =>
  import('./views/ATTENDANCE/attendance_summary'),
);
const AdminAttendanceManagement = React.lazy(() =>
  import('../src/admin-dashboard/attendance-management'),
);
const AdminSeparation = React.lazy(() =>
  import('../src/admin-dashboard/admin-separation'),
);
const ShiftCreation = React.lazy(() =>
  import(
    '../src/admin-dashboard/attendance-management/shift-management/shift_creation'
  ),
);
const ShiftManagement = React.lazy(() =>
  import(
    '../src/admin-dashboard/attendance-management/shift-management/shift-management'
  ),
);
const TravelAndClaim = React.lazy(() => import('./views/TRAVEL_AND_CLAIM'));
const TravelRequest = React.lazy(() =>
  import('./views/TRAVEL_AND_CLAIM/components/trave_request'),
);
const TravelAndClaimAdmin = React.lazy(() =>
  import('./admin-dashboard/travel-and-claim/index'),
);
const CreateSurvey = React.lazy(() => import('./admin-dashboard/survey/index'));
const ReassignMrf = React.lazy(() => import('./admin-dashboard/mrf/index'));
const Mapping = React.lazy(() => import('./admin-dashboard/map/index'));
const Payroll = React.lazy(() => import('./views/PAYROLL'));
const PayrollDetail = React.lazy(() => import('./views/PAYROLL/PayrollDetail'));
const TicketManagment = React.lazy(() =>
  import('./views/TicketManagementSystem'),
);
const Tms = React.lazy(() => import('./admin-dashboard/tms'));
const AddCommercialProcess =React.lazy(()=>import('./admin-dashboard/commercial/AddCommercialProcess'))
const CommercialAttributs =React.lazy(()=>import('./admin-dashboard/commercial/CommercialAttributs'))
const AllCommercialProcess =React.lazy(()=>import('./admin-dashboard/commercial/AllCommercialProcess'))
const EmployeeDetail = React.lazy(()=>import('./admin-dashboard/accounts/employeeDetails/EmployeeDetail'))

const payrollAdmin = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin'),
);
const PayrollEmployee = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin/PayrollEmployee'),
);
const PayRollSetting = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin/PayRollSetting'),
);
const AddNewEarning = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin/AddNewEarning'),
);
const AddEarningType = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin/AddEarningType'),
);
const SalaryComponent = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin/SalaryComponent'),
);
const PreTaxDeduction = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin/PreTaxDeduction'),
);
const PostTaxDeduction = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin/PostTaxDeduction'),
);
const NewSalaryTemplet = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin/NewSalaryTemplet'),
);
const AddnewEmployee = React.lazy(() =>
  import('../src/admin-dashboard/payroll-admin/AddnewEmployee'),
);
const PayRun = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/PayRun'),
);
const Tds = React.lazy(() => import('./admin-dashboard/payroll-admin/Tds'));
const PayrollLoans = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/PayrollLoans'),
);
const PayrollReimbursments = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/PayrollReimbursments'),
);
const PayrollSalaryRevision = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/PayrollSalaryRevision'),
);
const ProofOfInvestments = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/ProofOfInvestments'),
);
const StatutoryComponents = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/StatutoryComponents'),
);
const Taxes = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/companyinfo'),
);
const AttendanceInOut = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/attendanceInOut'),
);
const ClosedMRF = React.lazy(() => import('./views/referral/closedMRF'));
const Admin = React.lazy(() => import('./Admin-Panel/admin-dashboard'));
const PayrollReports = React.lazy(() =>
  import('./admin-dashboard/payroll-admin/Reports'),
);
// import Announcement
const  Announcement= React.lazy(() => import('./views/announcement/Announcement'));
const  All_Announcement= React.lazy(() => import('./views/announcement/All_Announcement'));
const  Add_Announcement= React.lazy(() => import('./views/announcement/Add_Announcement'));


const Permissions = React.lazy(() => import('./Permissions/Index'));
const MysowftwerAssest=React.lazy(()=>import('./views/ASSETS MANAGEMENT/EMP_Asset_Management/asset_profile_emp/mysoftwareassets'))
const Messages = React.lazy(() => import('./views/messages/messages'));
const DashBoard = React.lazy(() => import('../src/Dashboard/index'));
const Reception = React.lazy(() => import('./reception/guest_form'));
const GuestDetails = React.lazy(() => import('./reception/guest_details'));
const EmployeeForm = React.lazy(() => import('./reception/employee_form'));
const WebCast =React.lazy(()=>import('./webCast/Web_Cast'))
const EmployeeDetails = React.lazy(() =>
  import('./reception/employee_details'),
);
const Reimbursement = React.lazy(() =>
  import('./views/reimbursement/reimbursement'),
);
const ReimbursementType = React.lazy(() =>
  import('./views/reimbursement/reimbursement_type'),
);
const ReimbursementRules = React.lazy(() =>
  import('./views/reimbursement/reimbursement_rules'),
);
const ReimbursementApproval = React.lazy(() =>
  import('./views/reimbursement/ReimbursementApproval'),
);
const AttendanceLock = React.lazy(() =>
  import(
    '../src/admin-dashboard/attendance-management/shift-management/attendance-lock'
  ),
);
const PromotionMail = React.lazy(() =>
  import(
    '../src/admin-dashboard/promotion-mail/PromotionMail'
  ),
);
// const AttendanceReports = React.lazy(()=>import('../src/admin-dashboard/attendance-management/shift-management/reports'))
const RosterManagement = React.lazy(() =>
  import(
    '../src/admin-dashboard/attendance-management/shift-management/roster_management'
  ),
);
const AttendanceReports = React.lazy(() =>
  import(
    '../src/admin-dashboard/attendance-management/shift-management/reports'
  ),
);
const AdminAppPermission = React.lazy(() =>
  import(
    '../src/admin-dashboard/attendance-management/shift-management/app-permission'
  ),
);
const PolicyManagement = React.lazy(() => import('./policies/index'));
const OrganizationPolicies = React.lazy(() => import('./policies/Policies'));
const SkipClearance = React.lazy(() =>
  import('../src/admin-dashboard/admin-separation/separation/skipClearance'),
);
const Projects = React.lazy(() => import('./taskManagement/Projects'));
const Tasks = React.lazy(() => import('./taskManagement/Tasks'));

const ProjectCategory = React.lazy(() =>
  import('./taskManagement/ProjectCategory'),
);
const AdminDashboardEntities = React.lazy(() =>
  import('./admin-dashboard/entities/allEntities'),
);
const BookingMeetingRoomScreen = React.lazy(() =>
  import(
    './views/FMS/fms_management/booking_meeting_room/booking_meeting_room_screen'
  ),
);
const BookingMeetingRoom = React.lazy(() =>
  import(
    './views/FMS/fms_management/booking_meeting_room/booking_meeting_room'
  ),
);
const AllLocations = React.lazy(() =>
  import('../src/admin-dashboard/locations/location'),
);
const RosterShift = React.lazy(()=>import('../src/admin-dashboard/attendance-management/shift-management/roster_shift'))
const LeaveConfiguration = React.lazy(()=>import('../src/admin-dashboard/attendance-management/shift-management/leave_configuration'));
// const LeaveConfiguration = React.lazy(()=>import('./admin-dashboard/attendance-management/shift-management/Leave_rule'));

const ManagerDashboard = React.lazy(() =>
  import('../src/mainDashbord/OverAllDashbord/TeamWiseDashboard'),
);
const ManualAttendance = React.lazy(()=>import('../src/admin-dashboard/attendance-management/shift-management/manual_attendance'))
const Setting = React.lazy(() => import('../src/Settings/Setting'));
const CreateNotes = React.lazy(() => import('../src/views/PMS/Notes'));
const MainDashboard = React.lazy(() => import('../src/views/main_dashboard/MainDashboard'));
const  WarningTemplate = React.lazy(()=>import('./admin-dashboard/warning templates/WarningTemplate')) ;


let urlData = getLocalDataAsObject('urlData1');
// console.log("your have permission route is", urlData)
// let arrayRout = getLocalDataAsObject("urlData")
//   ? getLocalDataAsObject("urlData")
//   : [];
let arrayRout = getLocalDataAsObject('urlData1')
  ? getLocalDataAsObject('urlData1')
  : [];

const routeObj = {
  main_dashboard: {
    path: '/dashboard/main',
    name: 'Main Dashboard',
    component: MainDashboard,
    exact: true,
  },
  my_info: {
    path: '/dashboard/my_info',
    name: 'My Info',
    component: My_info,
    exact: true,
  },
  notes_feedback: {
    path: '/dashboard/performance_feedback',
    name: 'Notes',
    component: CreateNotes,
    exact: true,
  },
  traning1: {
    path: '/dashboard/training/training1',
    name: 'training1',
    component: Training1,
    exact: true,
  },
  training2: {
    path: '/dashboard/training/training2',
    name: 'training2',
    component: Training2,
  },

  documents: {
    path: '/dashboard/documents',
    name: 'Documents',
    component: Documents,
  },
  offer_letter: {
    path: '/dashboard/offer_letter',
    name: 'Offer Letter',
    component: Offer_Letter,
  },
  detail: { path: '/dashboard/detail', name: 'Detail', component: Detail },
  policies: {
    path: '/dashboard/policies',
    name: 'Policies',
    component: Policies,
  },
  mrf_manager: {
    path: '/dashboard/mrf_manager',
    name: 'MRF Form',
    component: MRF_Manager,
    exact: true,
  },
  create_mrf: {
    path: '/dashboard/create-mrf/:id?/:updateType',
    name: 'Create MRF',
    component: Create_MRF,
    exact: true,
  },
  interview: {
    path: '/dashboard/interview',
    name: 'Interview Panel',
    component: Interview,
    exact: true,
  },
  referral: {
    path: '/dashboard/referral',
    name: 'Employee Referral',
    component: Referral,
    exact: true,
  },
  jobdiscription: {
    path: '/dashboard/jobdiscription',
    name: 'Job Description',
    component: JobDiscription,
    exact: true,
  },
  change_password: {
    path: '/dashboard/changepassword',
    name: 'Change Password',
    component: ChangePassword,
  },
  mrf_finance: {
    path: '/dashboard/mrf_finance',
    name: 'MRF Finance',
    component: MRF_Finance,
    exact: true,
  },
  process_commercials: {
    path: '/admin-dashboard/process_commercials/:id?',
    name: 'Commercial Process',
    component: AddCommercialProcess,
    exact: true,
  },
  commercial_attributes: {
    path: '/admin-dashboard/commercial_attributes',
    name: 'Commercial Attributs',
    component: CommercialAttributs,
    exact: true,
  },
  all_commercials: {
    path: '/admin-dashboard/all_commercials',
    name: 'Commercial Attributs',
    component: AllCommercialProcess,
    exact: true,
  },
  admin_department: {
    path: '/dashboard/admin/Admin_Department',
    name: 'Admin Department',
    component: Admin_Department,
    exact: true,
  },
  recruitment_status: {
    path: '/dashboard/recruitment/Status',
    name: 'Current Status',
    component: Status,
    exact: true,
  },
  recruitment_jd_template: {
    path: '/dashboard/recruitment/JD_Template',
    name: 'Job Description',
    component: JD_Template,
    exact: true,
  },
  dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
  },
  hr_dashboard: {
    path: '/hr-dashboard',
    name: 'HR Dashboard',
    component: Hr_Dashboard,
    exact: true,
  },
  hr_manager_dashboard: {
    path: '/hr-manager-dashboard',
    name: 'HR Manager Dashboard',
    component: Hr_Manager_Dashboard,
    exact: true,
  },
  hr_manager_dashboard: {
    path: '/hr-dashboard',
    name: 'HR Dashboard',
    component: Hr_1_Dashboard,
    exact: true,
  },
  landing_dashboard: {
    path: '/hr-manager-dashboard/dashboard',
    name: 'Landing Dashboard',
    component: LandingDashboard,
    exact: true,
  },
  separation: {
    path: '/dashboard/separation',
    name: 'Separation',
    component: Seperation,
    exact: true,
  },
  separation_received: {
    path: '/dashboard/separation/received',
    name: 'Separation Received',
    component: SeperationReceived,
    exact: true,
  },
  separation_fullnfinal: {
    path: '/dashboard/separation/fullnfinal',
    name: 'Separation Full N Final',
    component: FullNFinal,
    exact: true,
  },
  request_employee_separation: {
    path: '/dashboard/separation/request_employee_separation',
    name: 'Request Employee Separation',
    component: Requestemployeeseparation,
    exact: true,
  },
  EmployeeExitInterview: {
    path: '/dashboard/separation/exit_interview',
    name: 'Employee Exit Interview',
    component: EmployeeExitInterview,
    exact: true,
  },
  all_separation_hr: {
    path: '/dashboard/separation/all_separation',
    name: 'All Separation',
    component: AllSeparationHR,
    exact: true,
  },
  admin_separation: {
    path: '/dashboard/admin/separation',
    name: 'AdminSeparation',
    component: AdminSeparation,
    exact: true,
  },
  skip_employee_clearance: {
    path: '/dashboard/admin/separation/skip-clearance',
    name: 'SkipEmployeeClearance',
    component: SkipClearance,
    exact: true,
  },
  asset_management: {
    path: '/dashboard/asset-management',
    name: 'Asset Management',
    component: AssetManagement,
    exact: true,
  },
  software_asset_management: {
    path: '/dashboard/software-asset-management',
    name: 'Software Asset Management',
    component: SoftwareAssetManagement,
    exact: true,
  },
  fms_management: {
    path: '/dashboard/fms',
    name: 'Facility Management',
    component: FmsManagement,
    exact: true,
  },
  employee_assets_management: {
    path: '/dashboard/asset-management/employee-assets-management',
    name: 'Employee Assets Management',
    component: EmployeeAssetsManagement,
    exact: true,
  },
  itsupport_assets_management: {
    path: '/dashboard/asset-management/itsupport-assets-management',
    name: 'IT Support Assets Management',
    component: ItSupportAssetsManagement,
    exact: true,
  },

  admin_dashboard: {
    path: '/admin-dashboard',
    name: 'Admin Dashboard',
    component: AdminDashboard,
    exact: true,
  },
  admin_dashboard_accounts: {
    path: '/admin-dashboard/accounts',
    name: 'Admin Dashboard Accounts',
    component: AdminDashboardAccounts,
    exact: true,
  },

  pms: {
    path: '/dashboard/pms',
    name: 'PMS',
    component: PmsManagement,
    exact: true,
  },
  pms_employee: {
    path: '/dashboard/pms/pms-employee',
    name: 'PMS Employee',
    component: PmsEmployee,
    exact: true,
  },
  pms_admin: {
    path: '/dashboard/pms/pms-admin',
    name: 'PMS Admin',
    component: PmsAdmin,
    exact: true,
  },
  software_employee_section: {
    path: '/dashboard/software-employee-section',
    name: 'SoftwareEmployee',
    component: MysowftwerAssest,
    exact: true,
  },
  pms_my_kpi: {
    path: '/dashboard/pms/my-kpi',
    name: 'PMS My KPI',
    component: PmsMyKpi,
    exact: true,
  },
  pms_my_team: {
    path: '/dashboard/pms/my-team',
    name: 'PMS My Team',
    component: PmsMyTeam,
    exact: true,
  },
  pms_rating_feedback: {
    path: '/dashboard/pms/start_rating/:user_id/:financial_year_id?/:rating_duration_id?',
    name: 'PMS My Team',
    component: StartRatingfeedback,
    exact: true,
  },
  pms_team_ranking: {
    path: '/dashboard/pms/team-ranking',
    name: 'PMS Team Ranking',
    component: PmsTeamRanking,
    exact: true,
  },
  pms_manage_kra: {
    path: '/dashboard/pms/manage-kra',
    name: 'PMS Manage KRA',
    component: PmsManageKra,
    exact: true,
  },
  pms_kpi_guidelines: {
    path: '/dashboard/pms/kpi-guidelines',
    name: 'PMS KPI Guidelines',
    component: PmsKpiGuidelines,
    exact: true,
  },
  pms_team_hierarchy: {
    path: '/dashboard/pms/team-hierarchy',
    name: 'PMS Team Hierarchy',
    component: PmsTeamHierarchy,
    exact: true,
  },
  probation_reviews: {
    path: '/dashboard/pms/probation-reviews',
    name: 'Probation Review',
    component: ProbationReviews,
    exact: true,
  },
  admin_probation_review: {
    path: '/dashboard/pms/admin-probation-review',
    name: 'Admin Probation Review',
    component: AdminProbationReviews,
    exact: true,
  },
  employee_probation: {
    path: '/dashboard/pms/employee_probation',
    name: 'EmployeeProbation',
    component: EmployeeProbation,
    exact: true,
  },
  // onboarding employee route
  onboarding_employee: {
    path: '/hrms/onboarding',
    name: 'Onboarding Employee',
    component: OnboardingEmployeeDashboard,
    exact: true,
  },
  feedback_details: {
    path: '/dashboard/feedback-details',
    name: 'Feedback Details',
    component: FeedbackDetails,
    exact: true,
  },
  attendance_management: {
    path: '/dashboard/attendance-management',
    name: 'Attendance Management',
    component: AttendanceManagement,
    exact: true,
  },
  leave_management: {
    path: '/dashboard/leave-management',
    name: 'Leave Management',
    component: LeaveManagement,
    exact: true,
  },
  holiday_list: {
    path: '/dashboard/attendance-management/holiday',
    name: 'Holiday List',
    component: HolidayList,
    exact: true,
  },
  add_holiday: {
    path: '/dashboard/admin/attendance-management/add-holiday',
    name: 'AddHoliday',
    component: AddHoliday,
    exact: true,
  },
  it_declaration: {
    path: '/dashboard/it_declaration',
    name: 'ITDeclaration',
    component: ITDeclaration,
    exact: true,
  },
  view_other_attendance: {
    path: '/dashboard/attendance-management/view-other-attendance',
    name: 'ViewOtherAttendance',
    component: ViewOtherAttendance,
    exact: true,
  },
  team_attendance: {
    path: '/dashboard/attendance-management/team-attendance',
    name: 'TeamAttendance',
    component: TeamAttendance,
    exact: true,
  },
  attendance_summary: {
    path: '/dashboard/attendance-management/attendance-summary',
    name: 'AttendanceSummary',
    component: AttendanceSummary,
    exact: true,
  },
  admin_attendance_management: {
    path: '/dashboard/admin/attendance-management',
    name: 'AdminAttendanceManagement',
    component: AdminAttendanceManagement,
    exact: true,
  },
  admin_attendance_management_shift_creation: {
    path: '/dashboard/admin/attendance-management/shift-creation',
    name: 'AdminAttendanceManagementShiftCreation',
    component: RosterShift,
    exact: true,
  },
  admin_attendance_management_credit_leave: {
    path: '/dashboard/admin/attendance-management/credit-leave',
    name: 'AdminAttendanceCreditLeave',
    component: ShiftManagement,
    exact: true,
  },
  admin_attendance_lock: {
    path: '/dashboard/admin/attendance-management/attendance-lock',
    name: 'AdminAttendanceLock',
    component: AttendanceLock,
    exact: true,
  },
  admin_attendance_reports: {
    path: '/dashboard/admin/attendance-management/reports',
    name: 'AttendanceReports',
    component: AttendanceReports,
    exact: true,
  },
  roster_management: {
    path: '/dashboard/admin/attendance-management/roster-management',
    name: 'RosterManagement',
    component: RosterManagement,
    exact: true,
  },
  admin_app_permission: {
    path: '/dashboard/admin/attendance-management/admin_app_permission',
    name: 'AdminAppPermission',
    component: AdminAppPermission,
    exact: true,
  },
  //_______________EXIT INTERVIEW ___________________
  exit_interview: {
    path: '/dashboard/separation/exit_interview',
    name: 'Exit Interview',
    component: ExitInterview,
    exact: true,
  },
  // _____________travel and claim__________________
  travel: {
    path: '/dashboard/travel',
    name: 'Travel',
    component: TravelAndClaim,
    exact: true,
  },
  travel_request: {
    path: '/dashboard/travel/travel-request',
    name: 'TravelRequest',
    component: TravelRequest,
    exact: true,
  },
  // travel and claim module
  travel_and_claim_admin: {
    path: '/dashboard/admin/travel-and-claim',
    name: 'TravelAndClaimAdmin',
    component: TravelAndClaimAdmin,
    exact: true,
  },

  ticket_management_system: {
    path: '/dashboard/ticket-management-system',
    name: 'TicketManagementSystem',
    component: TicketManagment,
    exact: true,
  },

  admin_survey: {
    path: '/admin-dashboard/survey/index',
    name: 'CreateSurvey',
    component: CreateSurvey,
    exact: true,
  },
  reassign_mrf: {
    path: '/admin-dashboard/reassignmrf',
    name: 'CreateSurvey',
    component: ReassignMrf,
    exact: true,
  },
  mapping: {
    path: '/admin-dashboard/map',
    name: 'CreateSurvey',
    component: Mapping,
    exact: true,
  },
  promotion_mail: {
    path: '/admin-dashboard/promotion-mail',
    name: 'Promotion Mail',
    component: PromotionMail,
    exact: true,
  },
  // payroll
  payroll: {
    path: '/dashboard/payroll',
    name: 'payroll',
    component: Payroll,
    exact: true,
  },
  payroll_admin: {
    path: '/dashboard/payroll-admin',
    name: 'payrollAdmin',
    component: payrollAdmin,
    exact: true,
  },

  payroll_setting: {
    path: '/dashboard/payroll-admin/setting',
    name: 'payrollSetting',
    component: PayRollSetting,
    exact: true,
  },
  payroll_employee: {
    path: '/dashboard/payroll-admin/employees',
    name: 'payrollEmployees',
    component: PayrollEmployee,
    exact: true,
  },
  add_new_earning: {
    path: '/dashboard/payroll-admin/add_new_earning',
    name: 'AddNewEarning',
    component: AddNewEarning,
    exact: true,
  },
  add_earning_type: {
    path: '/dashboard/payroll-admin/add_earning_type',
    name: 'AddEarningType',
    component: AddEarningType,
    exact: true,
  },
  salary_component: {
    path: '/dashboard/payroll-admin/salary-component',
    name: 'SalaryComponent',
    component: SalaryComponent,
    exact: true,
  },
  pre_taxDeduction: {
    path: '/dashboard/payroll-admin/pre-tax/:id?',
    name: 'PreTaxDeduction',
    component: PreTaxDeduction,
    exact: true,
  },
  post_taxDeduction: {
    path: '/dashboard/payroll-admin/post-tax/:id?',
    name: ' PostTaxDeduction',
    component: PostTaxDeduction,
    exact: true,
  },
  newSalary_template: {
    path: '/dashboard/payroll-admin/newSalary-template',
    name: 'NewSalaryTemplet',
    component: NewSalaryTemplet,
    exact: true,
  },
  payroll_payslip_details: {
    path: '/dashboard/payroll/payroll-payslip-details',
    name: 'payroll Details',
    component: PayrollDetail,
    exact: true,
  },
  admin_tms: {
    path: '/admin-dashboard/tms',
    name: 'tms',
    component: Tms,
    exact: true,
  },
  booking_meeting_room: {
    path: '/booking-meeting-room/:room?',
    name: 'Book Room',
    component: BookingMeetingRoom,
    exact: true,
  },
  booking_meeting_room_screen: {
    path: '/meeting-room/screen/:room?',
    name: 'Room Screen',
    component: BookingMeetingRoomScreen,
    exact: true,
  },
  add_new: {
    path: '/dashboard/payroll-admin/add_new',
    name: 'AddNewEmployee',
    component: AddnewEmployee,
    exact: true,
  },
  pay_run: {
    path: '/dashboard/payroll-admin/pay_run',
    name: 'PayRun',
    component: PayRun,
    exact: true,
  },
  pay_run_details: {
    path: '/admin-dashboard/pay-run-details/:id?/:year?/:button?',
    name: 'PayRun',
    component: PayRun,
    exact: true,
  },
  tds: {
    path: '/dashboard/payroll-admin/tds',
    name: 'Tds',
    component: Tds,
    exact: true,
  },
  payroll_loans: {
    path: '/dashboard/payroll-admin/payroll_loans',
    name: 'PayrollLoans',
    component: PayrollLoans,
    exact: true,
  },
  payroll_reimbursments: {
    path: '/dashboard/payroll-admin/payroll_reimbursments',
    name: 'PayrollReimbursments',
    component: PayrollReimbursments,
    exact: true,
  },
  payroll_salary_revision: {
    path: '/dashboard/payroll-admin/payroll_salary_revision',
    name: 'PayrollSalaryRevision',
    component: PayrollSalaryRevision,
    exact: true,
  },
  proof_of_investments: {
    path: '/dashboard/payroll-admin/proof_of_investments',
    name: 'ProofOfInvestments',
    component: ProofOfInvestments,
    exact: true,
  },
  statutory_components: {
    path: '/dashboard/payroll-admin/statutory_components',
    name: 'StatutoryComponents',
    component: StatutoryComponents,
    exact: true,
  },
  taxes: {
    path: '/dashboard/payroll-admin/taxes',
    name: 'Taxes',
    component: Taxes,
    exact: true,
  },
  attendance_in_out: {
    path: '/dashboard/payroll-admin/attendance_in_out',
    name: ' AttendanceInOut',
    component: AttendanceInOut,
    exact: true,
  },
  ClosedMRF: {
    path: '/dashboard/referral',
    name: ' ClosedMRF',
    component: ClosedMRF,
    exact: true,
  },
  admin: {
    path: '/admin',
    name: 'Admin Dashboard',
    component: Admin,
    exact: true,
  },
    // route announcement 
    announcement: {
      path: '/dashboard/announcement',
      name: 'Announcement',
      component: Announcement,
      exact: true,
    },
    add_announcement: {
      path: '/dashboard/announcement/add_announcement',
      name: 'Announcement',
      component: Add_Announcement,
      exact: true,
    },
    all_announcement: {
      path: '/dashboard/announcement/all_announcement',
      name: 'All Announcement',
      component: All_Announcement,
      exact: true,
    },
  payrollReports: {
    path: '/dashboard/payroll-admin/reports',
    name: 'Reports',
    component: PayrollReports,
    exact: true,
  },
  Reception: {
    path: '/guest-form',
    name: 'Guest Form',
    component: Reception,
    exact: true,
  },
  GuestDetails: {
    path: '/guest-details',
    name: 'Guest Details',
    component: GuestDetails,
    exact: true,
  },
  permissions: {
    path: '/permissions',
    name: 'Permissions',
    component: Permissions,
  },
  EmployeeForm: {
    path: '/employee-form',
    name: 'Employee Form',
    component: EmployeeForm,
    exact: true,
  },
  EmployeeDetails: {
    path: '/employee-details',
    name: 'Employee Details',
    component: EmployeeDetails,
    exact: true,
  },
  setting_page: {
    path: '/dashboard/Setting/Setting',
    name: 'Setting',
    component: Setting,
    exact: true,
  },
  messages: {
    path: '/messages',
    name: 'Chat',
    component: Messages,
    exact: true,
  },
    taskbox: {
    path: '/taskboard',
    name: 'Taskbox',
    component: DashBoard,
    exact: true,
  },
  reimbursement: {
    path: '/dashboard/reimbursement',
    name: 'Reimbursement',
    component: Reimbursement,
    exact: true,
  },
  reimbursement_type: {
    path: '/dashboard/reimbursement-type',
    name: 'Reimbursement Type',
    component: ReimbursementType,
    exact: true,
  },
  reimbursement_rules: {
    path: '/dashboard/reimbursement-rules',
    name: 'Reimbursement Rules',
    component: ReimbursementRules,
    exact: true,
  },
  reimbursement_approval: {
    path: '/dashboard/reimbursement-approval',
    name: 'Reimbursement Approval',
    component: ReimbursementApproval,
    exact: true,
  },
  policy_management: {
    path: '/dashboard/policy-management',
    name: 'Policy Management',
    component: PolicyManagement,
    exact: true,
  },
  policy_category: {
    path: '/dashboard/policy-category',
    name: 'Policy Catgory',
    component: PolicyManagement,
    exact: true,
  },
  organization_policies: {
    path: '/dashboard/organization-policies',
    name: 'Policies',
    component: OrganizationPolicies,
    exact: true,
  },
  task_management: {
    path: '/dashboard/task-management',
    name: 'Task Management',
    component: Tasks,
    exact: true,
  },
  tasks: {
    path: '/dashboard/task-management',
    name: 'Tasks',
    component: Tasks,
    exact: true,
  },
  projects: {
    path: '/dashboard/projects',
    name: 'Projects',
    component: Projects,
    exact: true,
  },
  project_category: {
    path: '/dashboard/project-category',
    name: 'Project Category',
    component: ProjectCategory,
    exact: true,
  },
  manage_entities: {
    path: '/admin-dashboard/manage-entities',
    name: 'Admin Dashboard Entity',
    component: AdminDashboardEntities,
    exact: true,
  },
  manage_locations: {
    path: '/dashboard/locations',
    name: 'Manage Locations',
    component: AllLocations,
    exact: true,
  },
  roster_shift: {
    path: '/dashboard/admin/attendance-management/roster-shift',
    name: 'RosterShift',
    component: RosterShift,
    exact: true,
  },
  leave_setings: {
    path: '/dashboard/admin/attendance-management/leave-settings',
    name: 'LeaveConfiguration',
    component: LeaveConfiguration,
    exact: true,
  },
  manual_attendance: {
    path: '/dashboard/admin/attendance-management/manual-attendance',
    name: 'ManualAttendance',
    component: ManualAttendance,
    exact: true,
  },
  manage_appraisals: {
    path: '/dashboard/appraisals',
    name: 'Manage Appraisals',
    component: AdminDashboardAppraisals,
    exact: true,
  },
  warning_letters_admin: {
    path: '/admin-dashboard/warning_letters',
    name: 'Warning Letters',
    component: WarningTemplate,
    exact: true,
  },
  // manager_dashboard: {
  //   path: '/manager-dashboard/:id',
  //   name: 'Manager Dashboard',
  //   component: ManagerDashboard,
  //   exact: true,
  // },
  manage_appraisals: {
    path: '/dashboard/appraisals',
    name: 'Manage Appraisals',
    component: AdminDashboardAppraisals,
    exact: true,
  },
  create_kudos: {
    path: '/dashboard/community/create_kudos',
    name: 'kudos',
    component: create_kudos,
    exact: true,
  },
  feeds: {
    path: '/dashboard/community/feeds',
    name: 'kudos',
    component: feeds,
    exact: true,
  },
  kudos_approval: {
    path: '/dashboard/community/kudos_approval',
    name: 'kudos',
    component: KudosApprovalManagement,
    exact: true,
  },
  employee_history: {
    path: '/dashboard/employee_history/:id?/:type?',
    name: 'Employee History',
    component: EmployeeDetail,
    exact: true,
  },
  webcast: {
    path: '/dashboard/webcast',
    name: 'webCast',
    component: WebCast,
    exact: true,
  },
  attrition_dashboard:{
    path: '/dashboard/attrition-dashboard',
    name: 'Attrition Dashboard',
    component: attritionDashboard,
    exact: true,
  },
  shrinkage_dashboard:{
    path: '/dashboard/shrinkage-dashboard',
    name: 'Shrinkage Dashboard',
    component: shrinkageDashboard,
    exact: true,
  }
};

let routeObjReq = [];

if (arrayRout && arrayRout.length) {
  arrayRout.map((item, index) => {
    if (routeObj?.[item.route_key]) {
      routeObjReq.push(routeObj[item.route_key]);
    }
  });
}
console.log('sjshjhsjdcd', routeObj);

export default routeObjReq;
