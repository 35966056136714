import React, { useEffect, useState } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';

import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';

import { diffBetweenTimes, comapareTime, timeAdd } from 'src/utils/common';

import { CustButton } from 'src/reusable';
import moment from 'moment';
import { timeGap } from 'src/utils/common';
import swal from 'sweetalert';

const ApprovalConfirmModal = props => {
  const api = new API();
  const {
    title,
    isShow,
    closeConfirmModal,
    handleOnSubmit,
    data,
    currentSelectedAction,
    isLoading,
  } = props;

  const counts = [];
  for (let i = 0; i <= 100; i++) {
      counts.push({"label":i,"value":i});
  }

  let attendanceLogs =
    data?.attendance_logs && JSON.parse(data?.attendance_logs);
  attendanceLogs =
    attendanceLogs &&
    attendanceLogs.length > 0 &&
    attendanceLogs.filter(item => item.reason);

  const [comment, setComment] = useState('');
  const [counter, setCounter] = useState('');
  const [audits, setAudits] = useState('');
  const [approveForStatus, setApproveStatus] = useState(data?.approve_for);
  const [commentErrorMsg, setCommentErrorMsg] = useState('');
  const [viewLogs, setViewLogs] = useState(false);

  const diffActualTime = diffBetweenTimes(
    data?.actual_check_in_time === 'No Punches' ||
      data?.actual_check_in_time === 'Invalid date'
      ? '00'
      : data?.actual_check_in_time,
    data?.actual_check_out_time === 'No Punches' ||
      data?.actual_check_out_time === 'Invalid date'
      ? '00'
      : data?.actual_check_out_time,
  );
  const diffCorrectionTime = diffBetweenTimes(
    data?.corrected_check_in_time || 0,
    data?.corrected_check_out_time || 0,
  );
  const diffTotalTime = diffBetweenTimes(
    `${diffActualTime?.diffHours || 0}:${diffActualTime?.diffMinutes || 0}`,
    `${diffCorrectionTime?.diffHours || 0}:${diffCorrectionTime?.diffMinutes ||
      0}`,
  );

  const hasMinimumFiveWords = (text) => {
    const words = text.trim().split(/\s+/).filter(word => word.length > 0);
    return words.length >= 5;
  }

  const handleOnChange = e => {
    const { value } = e.target;
    if (value && commentErrorMsg) {
      setCommentErrorMsg('');
    }
    setComment(e.target.value);
  };

  const handleOnRightBtnClick = () => {
    let allowSubmit = false;
    if(data?.assign_hod == 0){
      allowSubmit = hasMinimumFiveWords(comment);
    }if(data?.is_hod_approval == 1 && data?.assign_hod == 1){
      allowSubmit = true;
    }
    if (allowSubmit && data.type === 'attendance_correction') {
      if (currentSelectedAction === 'reject' && !comment) {
        setCommentErrorMsg('Reason field is required');
        return;
      } else {
        if (data?.is_approved_pm != 1 && !audits && (data?.designation?.includes("Quality Analyst") || data?.designation?.includes("Quality Controller") || data?.designation?.includes("Quality Auditor")) 
        && currentSelectedAction === 'approve') {
          toast.error('Please enter no of audits');
          return;
        }if (!approveForStatus && currentSelectedAction === 'approve') {
          toast.error('Please select Approve For ');
          return;
        } 
        else {
        }
      }
      const params = {
        type: data.type,
        is_approved: currentSelectedAction,
        id: 1, //id is fixed not in use
        comment: currentSelectedAction === 'reject' ? comment : comment,
        correction_data: JSON.stringify([
          {
            id: data.id,
            approve_for:
            currentSelectedAction === 'approve' ? approveForStatus : null,
            comment: '',
            audits: audits ? audits?.value : 0
          },
        ]),
      };
      handleOnSubmit(params);
    }else{
      toast.error("Minimum 5 words required in reason!!")
    }
  };

  const formatValue = val => {
    return `${val < 0 ? '-' : ''} ${
      Math.abs(val) < 10 ? `0${Math.abs(val)}` : Math.abs(val)
    }`;
  };
  useEffect(() => {
    getCorrectionCounter();
    setApproveStatus(data?.approve_for)
  }, [data]);

  // get correction counter
  const getCorrectionCounter = async () => {
    let data1 = {
      emp_id : data?.emp_id
    }
    let result = await api.get(config.getCorrectionCounter,true,data1);
    if (result && result.code === 200) {
      setCounter(result.data.result);
    }
  };

  const handleApproveStatus = value => {
    let compareTime = value === 'full_day' ? '08:00:00' : '04:00:00';
    let status =
      comapareTime(actualNetWorking, compareTime) === 'isBefore' ? true : false;
    if (status) {
      swal({
        html: true,
        title: 'Are you sure?',
        text: `You are going to approval for ${
          value === 'full_day'
            ? `Full Day`
            : value === 'first_half_present'
            ? 'First half present'
            : `Second half present`
        } but net working hours is less then ${compareTime}`,
        icon: 'warning',
        buttons: ['No, cancel it!', 'Submit'],
        dangerMode: true,
      }).then(async function(isConfirm) {
        if (isConfirm) {
          setApproveStatus(value);
        } else {
          setApproveStatus('');
        }
      });
    } else {
      setApproveStatus(value);
    }
  };
  const actualCheckIn =
    data?.actual_check_in_time &&
    data?.actual_check_in_time != 'null' &&
    data?.actual_check_in_time !== 'Invalid date' &&
    data?.actual_check_in_time !== 'No Punches'
      ? data.actual_check_in_time
      : '00:00:00';
  const correctedCheckIn =
    data?.corrected_check_in_time &&
    data?.corrected_check_in_time != 'null' &&
    data?.corrected_check_in_time != 'Invalid date' &&
    data?.corrected_check_in_time != 'No Punches'
      ? data?.corrected_check_in_time
      : actualCheckIn;
  const diffOfCheckIn = timeGap(actualCheckIn, correctedCheckIn);
  const actualCheckOut =
    data?.actual_check_out_time &&
    data?.actual_check_out_time != 'null' &&
    data?.actual_check_out_time !== 'Invalid date' &&
    data?.actual_check_out_time !== 'No Punches'
      ? data.actual_check_out_time
      : '00:00:00';
  const correctedCheckOut =
    data?.corrected_check_out_time &&
    data?.corrected_check_out_time != 'null' &&
    data?.corrected_check_out_time !== 'Invalid date' &&
    data?.corrected_check_out_time !== 'No Punches'
      ? data?.corrected_check_out_time
      : actualCheckOut;
  const diffOfCheckout = timeGap(actualCheckOut, correctedCheckOut);
  const actualBreak =
    data?.actual_times?.breakHours ||
    data?.actual_times?.breakMinutes ||
    data?.actual_times?.breakSeconds
      ? `${
          data?.actual_times?.breakHours <= 9
            ? `0${data?.actual_times?.breakHours}`
            : data?.actual_times?.breakHours
        }:${
          data?.actual_times?.breakMinutes <= 9
            ? `0${data?.actual_times?.breakMinutes}`
            : data?.actual_times?.breakMinutes
        }:${
          data?.actual_times?.breakSeconds <= 9
            ? `0${data?.actual_times?.breakSeconds}`
            : data?.actual_times?.breakSeconds
        }`
      : '00:00:00';
  const correctedBreak =
    data?.corrected_times?.breakHours ||
    data?.corrected_times?.breakMinutes ||
    data?.corrected_times?.breakSeconds
      ? `${
          data?.corrected_times?.breakHours <= 9
            ? `0${data?.corrected_times?.breakHours}`
            : data?.corrected_times?.breakHours
        }:${
          data?.corrected_times?.breakMinutes <= 9
            ? `0${data?.corrected_times?.breakMinutes}`
            : data?.corrected_times?.breakMinutes
        }:${
          data?.corrected_times?.breakSeconds <= 9
            ? `0${data?.corrected_times?.breakSeconds}`
            : data?.corrected_times?.breakSeconds
        }`
      : actualBreak;
  const diffOfBreak = timeGap(actualBreak, correctedBreak);
  const actualWorkingHrs = timeGap(actualCheckIn, actualCheckOut);
  const correctedWorkingHrs = timeGap(correctedCheckOut, correctedCheckIn);
  const differenceOfworkingHrs = timeGap(actualWorkingHrs, correctedWorkingHrs);
  const actualNetWorking = timeGap(actualWorkingHrs, actualBreak);
  const correctedNetworking = timeGap(correctedWorkingHrs, correctedBreak);
  const differenceOfNetWorking = timeGap(actualNetWorking, correctedNetworking);

  const closeModalfun = () => {
    closeConfirmModal();
    setApproveStatus('');
    setComment('');
  };

  return (
    <div>
      <CModal show={isShow} onClose={closeModalfun} size="lg" backdrop={false}>
        <CModalHeader closeButton>
          <CModalTitle style={{display:"inline-flex"}}>{title}
          {counter.length > 0 &&
          <div
                className="clock-1"
                style={{
                  fontSize: '20px',
                  backgroundColor: '#1890ff',
                  color: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5px',
                  height: '100%',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginLeft:"220px"
                }}
              >
                <div className="hours">
                  <div className="">
                  Correction Count :&nbsp; 
                  {counter && counter.map((count) => (<>
                  {moment(count.date).format("YYYY-MM-DD") === moment(data.date).format("YYYY-MM-DD") && (
                    count.correction_count
                  )
                  }
                </>)
                  )}&nbsp;
                  </div>
                </div>
        
              </div>}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {data && (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <td colSpan={2}>
                      <strong style={{color:"darkblue"}}>Attendance Date :&nbsp;</strong>
                      <b style={{color:"brown"}}>{moment(data?.date).format('DD-MM-YYYY') || 'No Date'}</b>
                    </td>
                    <td colSpan={3}>
                      <strong style={{color:"darkblue"}}>Reporting Manager :&nbsp;</strong>
                      <b style={{color:"brown"}}>{data?.reporting_manager}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <strong style={{color:"darkblue"}}>Designation :&nbsp;</strong>
                      <b style={{color:"brown"}}>{data?.designation}</b>
                    </td>
                    <td colSpan={3}>
                      <strong style={{color:"darkblue"}}>Function :&nbsp;</strong>
                      <b style={{color:"brown"}}>{data?.subdepartment}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <strong style={{color:"darkblue"}}>Project :&nbsp;</strong>
                      <b style={{color:"brown"}}>{data?.project}</b>
                    </td>
                  </tr>
                  <tr>
                    <th width="20%"></th>
                    <th width="20%">Actual Time</th>
                    <th width="25%">Correction Time</th>
                    <th width="25%">Difference</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Check In</th>
                    <td>{actualCheckIn}</td>
                    <td>{correctedCheckIn}</td>
                    <td>{diffOfCheckIn}</td>
                  </tr>
                  <tr>
                    <th>Check Out</th>
                    <td>{actualCheckOut}</td>
                    <td>{correctedCheckOut}</td>
                    <td>{diffOfCheckout}</td>
                  </tr>
                  <tr>
                    <th>Break</th>
                    <td>{actualBreak}</td>
                    <td>{correctedBreak}</td>
                    <td>{diffOfBreak}</td>
                  </tr>

                  <tr>
                    <th>Working Hours</th>
                    <td className="">
                      <strong>
                        {actualWorkingHrs}
                        {/* {`${formatValue(
                          diffActualTime.diffHours,
                        )}:${formatValue(diffActualTime.diffMinutes)}`} */}
                      </strong>
                    </td>
                    <td className="">
                      <strong>
                        {correctedWorkingHrs}
                        {/* {`${formatValue(
                          diffCorrectionTime.diffHours,
                        )}:${formatValue(diffCorrectionTime.diffMinutes)}`} */}
                      </strong>
                    </td>
                    <td>{differenceOfworkingHrs}</td>
                  </tr>

                  <tr>
                    <th>Net Working</th>
                    <td
                      style={{
                        color: actualNetWorking[1] < 8 ? 'red' : 'green',
                      }}
                    >
                      {actualNetWorking}
                    </td>
                    <td
                      style={{
                        color: correctedNetworking[1] < 8 ? 'red' : 'green',
                      }}
                    >
                      {correctedNetworking}
                    </td>
                    <td
                      style={{
                        color: differenceOfNetWorking[1] < 1 ? 'green' : 'red',
                      }}
                    >
                      {differenceOfNetWorking}
                    </td>
                  </tr>
                  {actualCheckOut == '00:00:00' && actualCheckIn == '00:00:00' ? '': 
                  <tr>
                    <th>Total Correction Hours</th>
                    <td></td>
                    <td></td>
                    <td>
                      <span style={{ fontWeight: 'bold', color: 'blue' }}>
                        {timeAdd(
                          timeAdd(diffOfCheckIn, diffOfCheckout),
                          diffOfBreak,
                        )}
                      </span>
                    </td>
                  </tr>}
                  <tr>
                    {data?.requested_check_in_reason && (<>
                      <th style={{color:"darkblue"}} colSpan={1}>Check IN Reason : </th>
                      <td colSpan={3}>{data?.requested_check_in_reason}</td>
                      </>)}
                  </tr>
                  <tr>
                  {data?.requested_check_out_reason && (<>
                       <th style={{color:"darkblue"}} colSpan={1}>Check OUT Reason : </th>
                       <td colSpan={3}>{data?.requested_check_out_reason}</td>
                       </>)}
                  </tr>
                  {currentSelectedAction === 'approve' &&  (data?.designation?.includes("Quality Analyst") || data?.designation?.includes("Quality Controller") || data?.designation?.includes("Quality Auditor")) && 
                     <tr style={{backgroundColor:"dodgerblue"}}>
                     <th style={{color:"white"}} colSpan={2}>{`Total No of Audits / Calls / Visits on ${moment(data?.date).format("DD-MM-YYYY")} :`}</th>
                     <td colSpan={1}>
                      {data?.is_approved_pm != 1 ? 
                     <Select
                    required={data?.is_approved_pm == 1 ? false : true}
                    isDisabled={data?.is_approved_pm == 1 ? true : false}
                    placeholder="NA"
                    onChange={(e) => setAudits(e)}
                    value={audits}
                    options={counts &&
                      counts.length > 0 &&
                      counts.map(val => {
                        val.label = val.label;
                        val.value = val.value;
                        return val;
                      })}
                  /> :             
                  <input
                  disabled
                  style={{color:"black",boxShadow:"none"}}
                  type='text'
                  className="form-control"
                  value={data?.no_of_audits}
                  id="comment"
                ></input>}
                     </td>
                     <td colSpan={1}></td>
                   </tr>}
                  {data?.is_approved_pm == 1 &&
                                <tr>
                                <th style={{color:"red"}} colSpan={1}>Manager Comment</th>
                                <td colSpan={2}>
                                  <b>{data?.reject_reason}</b>
                                </td>
                                <td colSpan={1}>
                                  <b style={{color:'red',textAlign:"right"}}>Approve At : </b><b>{moment(data?.approve_reject_pm_at).format("DD-MM-YYYY HH:mm:ss")}</b>
                                </td>
                  </tr>}
                  {}
                  {currentSelectedAction === 'approve' && (
                    <tr>
                      <th style={{color:"darkblue"}} colSpan={1}>Approve For</th>
                      <td colSpan={2}>
                        <select
                          className="form-control"
                          onChange={e => handleApproveStatus(e.target.value)}
                          value={approveForStatus}
                        >
                          <option hidden>Select</option>
                          <option value="full_day">Full Day</option>
                          <option value="first_half_present">
                            First Half Day
                          </option>
                          <option value="second_half_present">
                            Second Half Day
                          </option>
                        </select>
                      </td>
                      <td>
                        {attendanceLogs?.length > 0 && (
                          <button
                            className="btn btn-primary"
                            onClick={() => setViewLogs(!viewLogs)}
                          >
                            {`${viewLogs ? 'Hide' : 'View'} Logs`}
                          </button>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {viewLogs && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Emp ID</th>
                  <th scope="col">Person Name</th>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Requested Time</th>
                  <th scope="col">Attendance Status</th>

                  <th scope="col">Reason</th>
                </tr>
              </thead>
              <tbody>
                {attendanceLogs &&
                  attendanceLogs.length > 0 &&
                  attendanceLogs.map((logs, index) => (
                    <tr>
                      <th scope="row">{logs?.emp_id}</th>
                      <td>{logs?.person_name}</td>
                      <td>{moment(logs?.auth_date).format('DD-MM-YYYY')}</td>
                      <td>{logs?.auth_time}</td>
                      <td>{logs?.requestedTime}</td>
                      <td>{logs?.attendenceStatus}</td>
                      <td><b style={{color:"red"}}>{logs?.reason}</b></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          {/* {currentSelectedAction === 'reject' && ( */}
          <form>
            <div className="form-group">
              <label htmlFor="formGroupExampleInput">
                Reason <span className="required-field">*</span>
              </label>
              <textarea
                className="form-control"
                name="comment"
                value={comment}
                onChange={handleOnChange}
                id="comment"
                rows="3"
              ></textarea>
              <span className="error-msg">{commentErrorMsg}</span>
            </div>
          </form>

          {/* )} */}
        </CModalBody>
        <CModalFooter>
          <CustButton
            name="save-ar"
            text="Submit"
            color="primary"
            onClick={handleOnRightBtnClick}
            className="btn-loading btn-ladda"
            isDisabled={comment ? false : true}
            isLoading={isLoading}
          />
        </CModalFooter>
      </CModal>
      <ToastContainer/>
    </div>
  );
};

export default ApprovalConfirmModal;
