import React, { useState, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { isPermission } from 'src/utils/common';
import {
  CBadge,
  CNav,
  CInputRadio,
  CNavItem,
  CNavbar,
  CListGroup,
  CListGroupItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CProgress,
  CProgressBar,
  CRow,
  CLabel,
  CInput,
  CForm,
  CFormGroup,
  CFormText,
  CSelect,
  CCallout,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';

import swal from 'sweetalert';
import Alert from 'src/alert/alert';
import { toast } from 'react-toastify';

const ReferenceDetails = props => {
  let { getEmployeeDetailStatus, empStatus, empDetails, show, onClickActive} = props;
  const api = new API();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [ReferenceDetails, setReferenceDetails] = useState({
    emp_first_reference_name: '',
    emp_first_reference_contact: '',
    emp_first_reference_email: '',
    emp_first_reference_status:'',
    emp_second_reference_name: '',
    emp_second_reference_contact: '',
    emp_second_reference_email: '',
    emp_second_reference_status: '',
  });
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };

  useEffect(() => {
    getRefrenceDetails();
  }, []);
  const getRefrenceDetails = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getEmployeeReferenceDetails, true, data);
    if (result && result.code === 200) {
      if (empStatus && empStatus.reference_detail_status == 1) {
        setIsDisabled(true);
        setIsSubmit(false);
        setReferenceDetails(result.data[0]);
      }
    } else {
      // alert(result && result.message);
    }
  };
  const saveReferenceDetails = async () => {
    let data = ReferenceDetails;
    if(data?.emp_first_reference_contact && data?.emp_second_reference_contact && 
      data?.emp_first_reference_contact == data?.emp_second_reference_contact){
      toast.error("Both reference contacts are not allowed to be the same")
      return
    }

    data.user_id = empDetails && empDetails.id;
    console.log('>>>>>>>>>>>>', ReferenceDetails);

    if (!data.user_id) {
      delete data.user_id;
    }
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to add Reference Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.referencedetails, data);

        if (result.code === 200) {
          isAlertToggle('Reference Details Added Successfully');
          onClickActive(9)
          getEmployeeDetailStatus();
        } else {
          isAlertToggle(result && result.message);
        }
      }
    });
  };
  const onChangeInputReferenceDetails = e => {
    console.log(e.target.name,e.target.value)
    const { name, value } = e.target;
    setReferenceDetails({
      ...ReferenceDetails,
      [name]: value,
    });
  };

  const updateRefrenceDetails = async (e) => {
    e.preventDefault();
    let data = ReferenceDetails;
    if(data?.emp_first_reference_contact && data?.emp_second_reference_contact && 
      data?.emp_first_reference_contact == data?.emp_second_reference_contact){
      toast.error("Both reference contacts are not allowed to be the same")
    }else{
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to update Reference Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Update'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updatereferencedetails, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          getEmployeeDetailStatus();
          setIsSubmit(false);
          getRefrenceDetails()
        } else {
          toast.error(result.message);
        }
      }
    });
  }
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <label>First Reference</label>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name</label>
              <input
                disabled={show ? true : false}
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="emp_first_reference_name"
                value={ReferenceDetails.emp_first_reference_name}
                onChange={e => onChangeInputReferenceDetails(e)}
                placeholder='Enter First Reference Name'
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Contact</label>
              <input
                disabled={show ? true : false}
                type="tel"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="emp_first_reference_contact"
                value={ReferenceDetails.emp_first_reference_contact}
                onChange={e => onChangeInputReferenceDetails(e)}
                placeholder='Enter First Reference Contact'
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email</label>
              <input
                disabled={show ? true : false}
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="emp_first_reference_email"
                value={ReferenceDetails.emp_first_reference_email}
                onChange={e => onChangeInputReferenceDetails(e)}
                placeholder='Enter First Reference Email'
              />
            </div>
            {show && isPermission("reference_check") && <div className="form-group">
              <label htmlFor="status">Status</label>
              <select 
              className='form-control p-2'
              name="emp_first_reference_status"
              value={ReferenceDetails.emp_first_reference_status} 
              onChange={e => onChangeInputReferenceDetails(e)}
              disabled={ReferenceDetails.emp_first_reference_contact ? false : true}
              >
                  <option hidden>Select Status.... </option>
                  <option value="Done">Done</option>
                  <option value="Not Valid">Not Valid</option>
                  <option value="Call Not Received">Call Not Received</option>
                  <option value="Others">Others</option>          
                </select>
            </div>}
          </div>

          <div className="col-md-6">
            <label>Second Reference</label>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name</label>
              <input
                disabled={show ? true : false}
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="emp_second_reference_name"
                value={ReferenceDetails.emp_second_reference_name}
                onChange={e => onChangeInputReferenceDetails(e)}
                placeholder='Enter Second Reference Name'
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Contact</label>
              <input
                disabled={show ? true : false}
                type="tel"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="emp_second_reference_contact"
                value={ReferenceDetails.emp_second_reference_contact}
                onChange={e => onChangeInputReferenceDetails(e)}
                placeholder='Enter Second Reference Contact'
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email</label>
              <input
                disabled={show ? true : false}
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="emp_second_reference_email"
                value={ReferenceDetails.emp_second_reference_email}
                onChange={e => onChangeInputReferenceDetails(e)}
                placeholder='Enter Second Reference Email'
              />
            </div>
            {show && isPermission("reference_check") && <div className="form-group">
              <label htmlFor="status" >Status</label>
              <select 
              className='form-control p-2'
              name="emp_second_reference_status"
              value={ReferenceDetails.emp_second_reference_status} 
              onChange={e => onChangeInputReferenceDetails(e)}
              disabled={ReferenceDetails.emp_second_reference_contact ? false : true}
              >
                  <option hidden>Select Status.... </option>
                  <option value="Done">Done</option>
                  <option value="Not Valid">Not Valid</option>
                  <option value="Call Not Received">Call Not Received</option>
                  <option value="Others">Others</option> 
                </select>
            </div>}
            {isSubmit && !show &&  (
                <button
                  className="btn btn-primary float-right"
                  onClick={e => saveReferenceDetails()}
                >
                  Submit
                </button>
              )}
              {!isSubmit && show && (
                <button
                  className="btn btn-primary float-right"
                  onClick={e => updateRefrenceDetails(e)}
                >
                  Submit
                </button>
              )}
              {(!show && !isSubmit) && <>
              <button
                  className="btn btn-success float-right mt-3 ml-1"
                  type="button"
                  onClick={e => onClickActive(9)}
                >
                  Next{' '}
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
                <button
                  className="btn btn-info float-right mt-3"
                  type="button"
                  onClick={e => updateRefrenceDetails(e)}
                >
                  Update
                </button>
                </>}
          </div>
        </div>
      </form>
      <Alert
        isAlert={isAlert}
        isAlertToggle={isAlertToggle}
        alertMessage={alertMessage}
        isAlertToggleClose={isAlertToggleClose}
      />
    </div>
  );
};
export default ReferenceDetails;