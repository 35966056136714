
import React, { useState, useEffect } from 'react'
import {
    CModal,
    CModalBody,
} from '@coreui/react'
import './dailyRatingSurvey.css'
import { config } from 'src/utils/apiUrl'
import API from 'src/utils/apiCalling'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

const PolicyPopUp = (props) => {
    const api = new API()
    const history = useHistory();
    const { isOpen, setIsOpenPolicyModal, policyData } = props
    const [rating, setRating] = useState("")
    const [remark, setRemark] = useState("")

    const submitViewPolicy = async () => {
        let data = {
            policy_id: policyData?.policy_id,
        }
        let result = await api.post(config.insertViewPolicyData, data)
        if (result && result.code === 200) {
            setIsOpenPolicyModal(false)
        }
    };

    const handleOnViewPolicy = () => {
        history.push('/dashboard/organization-policies')
        setIsOpenPolicyModal(!isOpen)
        submitViewPolicy()
    };

    return (
        <div>
            <CModal
                show={isOpen}
                style={{ background: 'none', border: 'none' }}
                size="lg"
                backdrop="static"
                closeOnBackdrop={false}
                className="custom-backdrop"
            >
                <CModalBody>
                    <div className=" justify-content-center">
                        <div className=" rating_bg mt-5">
                            <div className="mt-2 text-center">
                                <h4 className="mb-0" style={{ color: "#6f4e37" }}>New Policy Update!</h4>
                                <div className="d-flex flex-row  mt-2">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md">
                                                <div>
                                                    <img alt="" src="images/icons/privacy-policy.gif" height="90" width="90" style={{ textAlign: "center" }} />
                                                    <div className='text_reting mt-2'>
                                                        <h6>Please be informed that a new policy has been updated in Glueple. You can view the updated policy in detail by visiting Glueple under <b>Policy Management {'>'} Policies {'>'} {policyData?.policy_name}</b>.</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='line_rating'></hr>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <button type="button" onClick={handleOnViewPolicy} className="btn btn-success mb-3"><span>View Policy</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CModalBody>
            </CModal>

        </div>

    )
}
export default PolicyPopUp