import React, { useEffect, useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CButton,
  CBadge,
  CCollapse,
  CCardBody,
} from '@coreui/react';
import moment from 'moment';
import { toast } from 'react-toastify';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { timeGap } from 'src/utils/common';
import { CDataTable } from '@coreui/react';
import Select from 'react-select';

const SelectedApproveAndRejectModal = ({
  isOpen,
  isViewModalOpen,
  heading,
  selectedData,
  correctionType,
  setSelectedData,
  getCorrectionApproval,
}) => {
  const api = new API();
  const [reason, setReason] = useState('');
  const [correctionData, setCorrectionData] = useState([]);
  const [details, setDetails] = useState([]);

  const handleApproveFor = (e, index) => {
    let newArr = [...correctionData];
    let obj = newArr[index];
    obj = { ...obj, approve_for: e.target.value };
    newArr[index] = obj;
    setCorrectionData(newArr);
  };

  const handleNoOfAudits = (e, index) => {
    let newArr = [...correctionData];
    let obj = newArr[index];
    obj = { ...obj, no_of_audits: e.value };
    newArr[index] = obj;
    setCorrectionData(newArr);
  };

  const counts = [];
  for (let i = 0; i <= 100; i++) {
      counts.push({"label":i,"value":i});
  }

  const hasMinimumFiveWords = (text) => {
    const words = text.trim().split(/\s+/).filter(word => word.length > 0);
    return words.length >= 5;
  }

  const approveRejectRequest = async () => {
    let auditData =  correctionData && correctionData.filter(item => ["Quality Analyst", "Quality Controller", "Quality Auditor"].some(role => item?.designation === role) && item?.no_of_audits == null && correctionType === 'Approve' && item?.is_approved_pm != 1 )
    let allowSubmit = false;
    let allowAuditSubmit = false;
    allowSubmit = hasMinimumFiveWords(reason);
    if(allowSubmit == false){
        toast.error("Minimum 5 words required in reason!!")
    }if(auditData?.length == 0){
      allowAuditSubmit = true
    }if(allowAuditSubmit == false){
      toast.error('Please enter no of audits for all users')
    }
    else if (reason && allowSubmit && allowAuditSubmit) {
      let correctionDatas =
      correctionData &&
      correctionData.length > 0 &&
      correctionData.map(item => {
        return {
          id: item?.id,
          approve_for: item?.approve_for,
          comment: item?.comment,
          audits: item?.no_of_audits || '0'
        }
      });
      let payload = {
        type: 'attendance_correction',
        is_approved: correctionType === 'Approve' ? 'approve' : 'reject',
        comment: reason,
        id: 1, //id is fixed not in use
        correction_data: JSON.stringify(correctionDatas),
      };
      let result = await api.post(config.approveRejectAttendance, payload);
      if (result && result.code === 200) {
        toast.success(result && result?.message);
        isViewModalOpen();
        setReason('');
        getCorrectionApproval();
        setSelectedData([]);
      } else {
        toast.error(result && result?.message);
      }
    }
  };

  const fields = [
    { key: 'emp_id', _style: { width: '5%' } },
    { key: 'employee_name', _style: { width: '10%' } },
    { key: 'attandance_date', _style: { width: '10%' } },
    { key: 'actual_check_in', _style: { width: '10%' } },
    { key: 'actual_check_out', _style: { width: '10%' } },
    { key: 'acctual_break', _style: { width: '10%' } },
    { key: 'actual_net_working', _style: { width: '10%' } },
    { key: 'approve_for', _style: { width: '10%' } },
    { key: 'no_of_audits',label:'No of Audits/ Calls /Visits', _style: { width: '10%' } },
    {
      key: 'show_details',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ];
  const toggleDetails = index => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const getBadge = status => {
    switch (status) {
      case 'In Stock':
        return 'success';
      case 'Inactive':
        return 'secondary';
      case 'In Use':
        return 'danger';
      case 'Inuse':
        return 'danger';
      case 'Banned':
        return 'danger';
      case 'pending':
        return 'warning';
      default:
        return 'primary';
    }
  };
  useEffect(() => {
    if (isOpen) {
      let newArr =
        selectedData &&
        selectedData.map(item => {
          return {
            id: item?.id,
            emp_id: item?.emp_id,
            approve_for: 'Full Day',
            employee_name: item?.employee_name,
            designation: item?.designation,
            is_approved_pm: item?.is_approved_pm,
            actual_check_in:
              item?.actual_check_in_time && item?.actual_check_in_time != 'null'
                ? item?.actual_check_in_time
                : 'No Punch',
            corrected_check_in:
              item?.corrected_check_in_time &&
              item?.corrected_check_in_time != 'null'
                ? item?.corrected_check_in_time
                : 'No Punch',
            actual_check_out:
              item?.actual_check_out_time &&
              item?.actual_check_out_time != 'null'
                ? item?.actual_check_out_time
                : 'No Punch',
            corrected_check_out:
              item?.corrected_check_out_time &&
              item?.corrected_check_out_time != 'null'
                ? item?.corrected_check_out_time
                : 'No Punch',
            acctual_break: item?.actual_times?.breakHours
              ? `${
                  item?.actual_times?.breakHours <= 9
                    ? `0${item?.actual_times?.breakHours}`
                    : item?.actual_times?.breakHours
                }:${
                  item?.actual_times?.breakMinutes <= 9
                    ? `0${item?.actual_times?.breakMinutes}`
                    : item?.actual_times?.breakMinutes
                }:${
                  item?.actual_times?.breakSeconds <= 9
                    ? `0${item?.actual_times?.breakSeconds}`
                    : item?.actual_times?.breakSeconds
                }`
              : '00:00:00',
            corrected_break: item?.corrected_times?.breakHours
              ? `${
                  item?.corrected_times?.breakHours <= 9
                    ? `0${item?.corrected_times?.breakHours}`
                    : item?.corrected_times?.breakHours
                }:${
                  item?.corrected_times?.breakMinutes <= 9
                    ? `0${item?.corrected_times?.breakMinutes}`
                    : item?.corrected_times?.breakMinutes
                }:${
                  item?.corrected_times?.breakSeconds <= 9
                    ? `0${item?.corrected_times?.breakSeconds}`
                    : item?.corrected_times?.breakSeconds
                }`
              : '00:00:00',
            difference_of_break:
              item?.corrected_times?.breakHours &&
              item?.actual_times?.breakHours
                ? timeGap(
                    `${item?.actual_times?.breakHours}:${item?.actual_times?.breakMinutes}:${item?.actual_times?.breakSeconds}`,
                    `${item?.corrected_times?.breakHours}:${item?.corrected_times?.breakMinutes}:${item?.corrected_times?.breakSeconds}`,
                  )
                : '00:00:00',
            actual_net_working: timeGap(
              item?.actual_check_in_time && item?.actual_check_out_time
                ? timeGap(
                    item?.actual_check_in_time &&
                      item?.actual_check_in_time != 'null' &&
                      item?.actual_check_in_time != 'No Punches'
                      ? item?.actual_check_in_time
                      : '00:00:00',
                    item?.actual_check_out_time &&
                      item?.actual_check_out_time != 'null' &&
                      item?.actual_check_out_time != 'No Punches'
                      ? item?.actual_check_out_time
                      : '00:00:00',
                  )
                : '00:00:00',
              item?.actual_times?.breakHours ||
                item?.actual_times?.breakMinutes ||
                item?.actual_times?.breakSeconds
                ? `${item?.actual_times?.breakHours}:${item?.actual_times?.breakMinutes}:${item?.actual_times?.breakSeconds}`
                : '00:00:00',
            ),
            corrected_net_working: timeGap(
              item?.corrected_check_in_time &&
                item?.corrected_check_in_time != 'null'
                ? timeGap(
                    item?.corrected_check_in_time &&
                      item?.corrected_check_in_time != 'null' &&
                      item?.corrected_check_in_time != 'No Punches'
                      ? item?.corrected_check_in_time
                      : '00:00:00',
                    item?.corrected_check_out_time &&
                      item?.corrected_check_out_time != 'null' &&
                      item?.corrected_check_out_time != 'No Punches'
                      ? item?.corrected_check_out_time
                      : '00:00:00',
                  )
                : '00:00:00',
              item?.corrected_times?.breakHours ||
                item?.corrected_times?.breakMinutes ||
                item?.corrected_times?.breakSeconds
                ? `${item?.corrected_times?.breakHours}:${item?.corrected_times?.breakMinutes}:${item?.corrected_times?.breakSeconds}`
                : '00:00:00',
            ),
            
            no_of_audits: item?.no_of_audits,
            attandance_date: moment(item?.date).format('DD-MM-YYYY'),
            check_in_reason: item?.requested_check_in_reason,
            check_out_reason: item?.requested_check_out_reason,
            comment: '',
          };
        });

      setCorrectionData(newArr);
    } else {
    }
  }, [isOpen]);
  return (
    <div>
      <CModal
        className="mt-5"
        show={isOpen}
        onClose={() => isViewModalOpen(!isOpen)}
        size="xl"
      >
        <CModalHeader closeButton>
          <CModalTitle>
            {correctionType === 'Approve'
              ? 'Approve Correction(s)'
              : 'Reject Correction(s)'}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CDataTable
            items={correctionData && correctionData}
            fields={fields}
            // columnFilter
            // tableFilter
            // footer
            itemsPerPageSelect
            itemsPerPage={2}
            hover
            // sorter
            pagination
            scopedSlots={{
              approve_for: (item, index) => (
                <td>
                  <select
                    className="form-control"
                    value={item?.approve_for}
                    onChange={e => handleApproveFor(e, index)}
                  >
                    <option hidden>Select</option>
                    <option value="Full Day" selected>
                      Full Day
                    </option>
                    <option value="First Half Day">First Half Day</option>
                    <option value="Second Half Day">Second Half Day</option>
                  </select>
                </td>
              ),
              no_of_audits: (item, index) => (
                <>
                  {correctionType === 'Approve' &&  ["Quality Analyst", "Quality Controller", "Quality Auditor"].some(role => item?.designation === role) ? (
                        <td>
                          {item?.is_approved_pm !== 1 ? (
                            <Select
                              required={item?.is_approved_pm !== 1}
                              isDisabled={item?.is_approved_pm === 1}
                              placeholder="NA"
                              maxMenuHeight={100}
                              onChange={(e) => handleNoOfAudits(e,index)}
                              value={item?.no_of_audits?{label:item?.no_of_audits,value:item?.no_of_audits}:"NA"}
                              options={
                                counts?.length > 0 &&
                                counts.map(val => ({
                                  label: val.label,
                                  value: val.value
                                }))
                              }
                            />
                          ) : (
                            <input
                              disabled
                              style={{ color: "black", boxShadow: "none" }}
                              type="text"
                              className="form-control"
                              value={item?.no_of_audits}
                              id="comment"
                            />
                          )}
                        </td>
                    ) : <td></td>}
                </>
              ),
              show_details: (item, index) => {
                return (
                  <td className="py-2">
                    <CButton
                      color="primary"
                      variant="outline"
                      shape="square"
                      size="sm"
                      onClick={() => {
                        toggleDetails(index);
                      }}
                    >
                      {details.includes(index) ? 'Hide' : 'Show'}
                    </CButton>
                  </td>
                );
              },
              details: (item, index) => {
                return (
                  <CCollapse show={details.includes(index)}>
                    <CCardBody>
                      <table className="table-striped">
                        <tr>
                          <th>Corrected Check In</th>
                          <td>{item?.corrected_check_in}</td>
                        </tr>
                        <tr>
                          <th>Corrected Check Out</th>
                          <td>{item?.corrected_check_out}</td>
                        </tr>
                        <tr>
                          <th>Corrected Break</th>
                          <td>{item?.corrected_break}</td>
                        </tr>
                        <tr>
                          <th>Difference Of Break</th>
                          <td>{item?.difference_of_break}</td>
                        </tr>
                        <tr>
                          <th>Corrected Net Working</th>
                          <td>{item?.corrected_net_working}</td>
                        </tr>
                        {item?.check_in_reason && (
                          <tr>
                            <th>Check In Reason</th>
                            <td>{item?.check_in_reason}</td>
                          </tr>
                        )}
                        {item?.check_out_reason && (
                          <tr>
                            <th>Check Out Reason</th>
                            <td>{item?.check_out_reason}</td>
                          </tr>
                        )}
                      </table>
                    </CCardBody>
                  </CCollapse>
                );
              },
            }}
          />
          <form>
            <div className="form-group">
              <label for="exampleFormControlInput1">Reason</label>
              <textarea
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                value={reason}
                onChange={e => setReason(e.target.value)}
                placeholder="Reason"
                rows="3"
              ></textarea>
            </div>
          </form>
          <CButton
            className="btn btn-primary text-start m-2"
            onClick={approveRejectRequest}
          >
            Submit
          </CButton>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default SelectedApproveAndRejectModal;
